import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('create_submitted_claim', {
    id: {
      type: 'String',
    },
    customer_id: {
      type: 'String',
    },
    practice_id: {
      type: 'String',
    },
    loss_uuid: {
      type: 'String',
    },
    loss_ref: {
      type: 'String',
    },
    case_uuid: {
      type: 'String',
    },
    case_ref: {
      type: 'String',
    },
    policy_number: {
      type: 'String',
    },
    archive_claim_id: {
      type: 'String',
    },
    document_ids: {
      type: 'Array',
    },
    paired_claim: {
      type: 'String',
    },
    PIO_loss_status: {
      type: 'String',
    },
    PIO_loss_outcome_time: {
      type: 'String',
    },
    PIO_loss_created_date: {
      type: 'String',
    },
    PIO_lossupdated_date: {
      type: 'String',
    },
    PIO_loss_total: {
      type: 'String',
    },
    PIO_payment_amount: {
      type: 'String',
    },
    assigned_to: {
      type: 'String',
    },
    createdAt: {
      type: 'String',
    },
    updatedAt: {
      type: 'String',
    },
  })
}
