<template>
  <MDropdown full-height tag="div">
    <template #trigger="{ toggle }">
      <MNavLink @click="toggle">
        <div class="notifications">
          <MIcon icon="bell" size="h4" title="Notifications" block />
          <div v-if="notifications" class="notifications__badge">
            <Animation type="boing">
              <MBadge>
                {{ notifications }}
              </MBadge>
            </Animation>
          </div>
        </div>
      </MNavLink>
    </template>
    <template #content="{ hide }">
      <MDropdownContent align="right">
        <MDropdownLink
          v-if="documentRequests"
          size="large"
          href="/hub/document-requests"
          tag="router-link"
          @click="hide"
        >
          <div class="site-header__dropdown-link">
            <strong>{{ documentRequests }}</strong>
            {{
              $t('notifications.document_requests', [
                documentRequests === 1 ? '' : 's',
              ])
            }}
          </div>
        </MDropdownLink>
        <MDropdownLink
          v-if="claimsRequiringAttention"
          size="large"
          href="/hub/requiring-attention"
          tag="router-link"
          @click="hide"
        >
          <div class="site-header__dropdown-link">
            <strong>{{ claimsRequiringAttention }}</strong>
            {{
              $t('notifications.requiring_attention', [
                claimsRequiringAttention === 1 ? '' : 's',
              ])
            }}
          </div>
        </MDropdownLink>
        <MSpacing
          v-if="!notifications"
          top="sm"
          right="md"
          bottom="sm"
          left="md"
        >
          <p>{{ $t('notifications.none') }}</p>
        </MSpacing>
      </MDropdownContent>
    </template>
  </MDropdown>
</template>

<script lang="ts" setup>
import {
  MIcon,
  MBadge,
  MDropdown,
  MDropdownContent,
  MDropdownLink,
  MNavLink,
  MSpacing,
} from '@boughtbymany/many-patterns'
import { ref, computed, watchEffect } from 'vue'

import Animation from '@/components/layout/Animation/Animation.vue'
import { useError } from '@/composables/useError'
import { getDocumentRequests } from '@/services/pio/documentManagementService'
import { useAuth } from '@/store/auth'
import { useClaimsRequiringAttention } from '@/store/claimsRequiringAttention'

const documentRequests = ref(0)
const claimsRequiringAttention = ref(0)
const { silentError } = useError()
const auth = useAuth()

const claimsRequiringAttentionComposable = useClaimsRequiringAttention()

watchEffect(() => {
  try {
    if (auth.currentPracticeId) {
      getDocumentRequests(auth.currentPracticeId).then((results) => {
        documentRequests.value = results.items?.length
      })
    }
  } catch (error: any) {
    silentError({
      message: `Document Requests - Unable to find document requests`,
      code: 'E3602',
      error,
    })
  }

  claimsRequiringAttention.value =
    claimsRequiringAttentionComposable.claimsRequiringAttentionTotal
})

const notifications = computed(() => {
  return documentRequests.value + claimsRequiringAttention.value
})
</script>

<style lang="scss" scoped>
.notifications {
  position: relative;

  &__badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(60%, -60%);
  }
}
</style>
