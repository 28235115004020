import { useFeatureFlags } from '@/store/featureFlags'

export const isBetterPolicyFetching = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('better-policy-fetching')
}

export const isClaimsConsolidation = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('claims_consolidation')
}

export const isMainBranchStructure = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('main_branch_structure')
}

export const isVetDataManagement = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('vet_data_management')
}

export const isVetFeesBalance = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('vet_fees_balance_cta')
}

export const isVersionNotificationEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('version-notification')
}

export const isSaveForLaterEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('show-save-for-later')
}

export default {
  isBetterPolicyFetching,
  isClaimsConsolidation,
  isMainBranchStructure,
  isVetDataManagement,
  isVetFeesBalance,
  isVersionNotificationEnabled,
  isSaveForLaterEnabled,
}
