import { defineStore } from 'pinia'

import { DraftClaim } from '@/domain/vetPortal/DraftClaim'
import { searchClaimsAsVetPractice } from '@/lib/claim'
import { formattedDates } from '@/lib/filter'
import { useAuth } from '@/store/auth'

export const useClaimsRequiringAttention = defineStore(
  'claimsRequiringAttention',
  {
    state: () =>
      ({
        claimsRequiringAttention: [],
        claimsRequiringAttentionTotal: 0,
        claimsRequiringAttentionFiltered: [],
      } as {
        claimsRequiringAttention: DraftClaim[]
        claimsRequiringAttentionTotal: number
        claimsRequiringAttentionFiltered: DraftClaim[]
      }),
    actions: {
      async setClaimsRequiringAttention() {
        const auth = useAuth()
        if (!auth.currentPractice) {
          return
        }
        const { items, total } = await searchClaimsAsVetPractice(
          'Draft',
          auth.currentPractice.company_id,
          {
            statuses: ['ManyPets Query'],
            limit: 1000,
          }
        )

        this.claimsRequiringAttention = items
        this.claimsRequiringAttentionTotal = total
      },

      async setClaimsRequiringAttentionFiltered(
        search: string,
        treatmentDates: string[]
      ) {
        const auth = useAuth()
        if (!auth.currentPractice) {
          return
        }

        const { items } = await searchClaimsAsVetPractice(
          'Draft',
          auth.currentPractice.company_id,
          {
            statuses: ['ManyPets Query'],
            limit: 1000,
            search,
            treatmentDateRange: formattedDates(treatmentDates),
          }
        )

        this.claimsRequiringAttentionFiltered = items
      },
      removeClaim(draftClaim: DraftClaim) {
        this.claimsRequiringAttention = this.claimsRequiringAttention.filter(
          (claim) => claim.id !== draftClaim.id
        )
        this.claimsRequiringAttentionFiltered =
          this.claimsRequiringAttentionFiltered.filter(
            (claim) => claim.id !== draftClaim.id
          )

        this.claimsRequiringAttentionTotal =
          this.claimsRequiringAttention.length
      },
    },
    getters: {
      getClaimsRequiringAttention: (state) => state.claimsRequiringAttention,
      getClaimsRequiringAttentionFiltered: (state) =>
        state.claimsRequiringAttentionFiltered,
    },
  }
)
