/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHubUsers = /* GraphQL */ `
  query GetHubUsers($id: ID!) {
    getHubUsers(id: $id) {
      id
      user_type
      video_vet_id
      video_vet_name
      company
      first_name
      last_name
      job_title
      role
      mobile
      verified_email
      password
      auth0_sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHubUsers = /* GraphQL */ `
  query ListHubUsers(
    $filter: ModelHubUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHubUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHubUserByAuth0Sub = /* GraphQL */ `
  query GetHubUserByAuth0Sub(
    $auth0_sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHubUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHubUserByAuth0Sub(
      auth0_sub: $auth0_sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchHubUsers = /* GraphQL */ `
  query SearchHubUsers(
    $filter: SearchableHubUsersFilterInput
    $sort: [SearchableHubUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHubUsersAggregationInput]
  ) {
    searchHubUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRegData = /* GraphQL */ `
  query GetRegData($id: ID!) {
    getRegData(id: $id) {
      id
      company_id
      company_name
      company_address1
      company_address2
      company_address3
      company_town
      company_country
      company_post_code
      company_phone
      company_website
      company_group
      company_email
      company_email_validated
      company_invite_string
      company_invite_current
      first_name
      last_name
      job_title
      role
      mobile
      password
      email
      email_validated
      invite_URL
      invite_current
      createdAt
      updatedAt
      company {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      regDataCompanyId
      __typename
    }
  }
`;
export const listRegData = /* GraphQL */ `
  query ListRegData(
    $filter: ModelRegDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_id
        company_name
        company_address1
        company_address2
        company_address3
        company_town
        company_country
        company_post_code
        company_phone
        company_website
        company_group
        company_email
        company_email_validated
        company_invite_string
        company_invite_current
        first_name
        last_name
        job_title
        role
        mobile
        password
        email
        email_validated
        invite_URL
        invite_current
        createdAt
        updatedAt
        company {
          id
          name
          address1
          address2
          address3
          town
          county
          country
          post_code
          phone
          website
          company_id
          group
          verified_email
          direct_payment_preferred
          remittance_email
          vat_number
          claims_handling_email
          claims_handling_contact_name
          remittance_contact_name
          practice_manager_name
          custom_1
          custom_2
          custom_3
          cat_dog_vet_algolia_id
          pio_vet_algolia_id
          createdAt
          updatedAt
          status
          registered_date
          first_claim_date
          latest_claim_date
          vet_practice_list_id
          type
          __typename
        }
        regDataCompanyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      address1
      address2
      address3
      town
      county
      country
      post_code
      phone
      website
      company_id
      group
      verified_email
      direct_payment_preferred
      remittance_email
      vat_number
      claims_handling_email
      claims_handling_contact_name
      remittance_contact_name
      practice_manager_name
      custom_1
      custom_2
      custom_3
      cat_dog_vet_algolia_id
      pio_vet_algolia_id
      createdAt
      updatedAt
      status
      registered_date
      first_claim_date
      latest_claim_date
      vet_practice_list_id
      type
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyByCompanyId = /* GraphQL */ `
  query GetCompanyByCompanyId(
    $company_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompanyByCompanyId(
      company_id: $company_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCompanies = /* GraphQL */ `
  query SearchCompanies(
    $filter: SearchableCompanyFilterInput
    $sort: [SearchableCompanySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCompanyAggregationInput]
  ) {
    searchCompanies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAccountDetails = /* GraphQL */ `
  query GetAccountDetails($id: ID!) {
    getAccountDetails(id: $id) {
      id
      user_id
      company_id
      account_name
      sortcode
      account_number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccountDetails = /* GraphQL */ `
  query ListAccountDetails(
    $filter: ModelAccountDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        company_id
        account_name
        sortcode
        account_number
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountDetailsByCompanyId = /* GraphQL */ `
  query GetAccountDetailsByCompanyId(
    $company_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAccountDetailsByCompanyId(
      company_id: $company_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        company_id
        account_name
        sortcode
        account_number
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerCompany = /* GraphQL */ `
  query GetCustomerCompany($id: ID!) {
    getCustomerCompany(id: $id) {
      id
      customer_id
      company_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerCompanies = /* GraphQL */ `
  query ListCustomerCompanies(
    $filter: ModelCustomerCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        company_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      customer_id
      family_name
      opt_out
      vet_added
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        customer_id
        family_name
        opt_out
        vet_added
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerByCustomerId = /* GraphQL */ `
  query GetCustomerByCustomerId(
    $customer_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByCustomerId(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        customer_id
        family_name
        opt_out
        vet_added
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: [SearchableCustomerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomerAggregationInput]
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        customer_id
        family_name
        opt_out
        vet_added
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getInsuredEntity = /* GraphQL */ `
  query GetInsuredEntity($id: ID!) {
    getInsuredEntity(id: $id) {
      id
      bbm_insured_entity_uuid
      policy_number
      policy_status
      start_date
      end_date
      company_id
      customer_id
      pet_name
      pet_type
      post_code
      family_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInsuredEntities = /* GraphQL */ `
  query ListInsuredEntities(
    $filter: ModelInsuredEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsuredEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bbm_insured_entity_uuid
        policy_number
        policy_status
        start_date
        end_date
        company_id
        customer_id
        pet_name
        pet_type
        post_code
        family_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchInsuredEntities = /* GraphQL */ `
  query SearchInsuredEntities(
    $filter: SearchableInsuredEntityFilterInput
    $sort: [SearchableInsuredEntitySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsuredEntityAggregationInput]
  ) {
    searchInsuredEntities(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        bbm_insured_entity_uuid
        policy_number
        policy_status
        start_date
        end_date
        company_id
        customer_id
        pet_name
        pet_type
        post_code
        family_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCustomersInvite = /* GraphQL */ `
  query GetCustomersInvite($id: ID!) {
    getCustomersInvite(id: $id) {
      id
      customer_id
      user_id
      company_id
      policy_number
      email
      customer_full_name
      user_name
      company_name
      expiry_time_date
      info_only {
        status
        invite_string
        invite_current
        __typename
      }
      info_and_claim {
        status
        invite_string
        invite_current
        __typename
      }
      block_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      block_all_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      invite_current
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomersInvites = /* GraphQL */ `
  query ListCustomersInvites(
    $filter: ModelCustomersInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomersInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        user_id
        company_id
        policy_number
        email
        customer_full_name
        user_name
        company_name
        expiry_time_date
        info_only {
          status
          invite_string
          invite_current
          __typename
        }
        info_and_claim {
          status
          invite_string
          invite_current
          __typename
        }
        block_vet_practice {
          status
          invite_string
          invite_current
          __typename
        }
        block_all_vet_practice {
          status
          invite_string
          invite_current
          __typename
        }
        invite_current
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomersInvites = /* GraphQL */ `
  query SearchCustomersInvites(
    $filter: SearchableCustomersInviteFilterInput
    $sort: [SearchableCustomersInviteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomersInviteAggregationInput]
  ) {
    searchCustomersInvites(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customer_id
        user_id
        company_id
        policy_number
        email
        customer_full_name
        user_name
        company_name
        expiry_time_date
        info_only {
          status
          invite_string
          invite_current
          __typename
        }
        info_and_claim {
          status
          invite_string
          invite_current
          __typename
        }
        block_vet_practice {
          status
          invite_string
          invite_current
          __typename
        }
        block_all_vet_practice {
          status
          invite_string
          invite_current
          __typename
        }
        invite_current
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDraftClaim = /* GraphQL */ `
  query GetDraftClaim($id: ID!) {
    getDraftClaim(id: $id) {
      id
      start_date
      end_date
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      draft_claim_id
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      admin_return_message
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_notes
      manual_process_reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDraftClaims = /* GraphQL */ `
  query ListDraftClaims(
    $filter: ModelDraftClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraftClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDraftClaimsByCompanyIdByDatetimeToCustomer = /* GraphQL */ `
  query GetDraftClaimsByCompanyIdByDatetimeToCustomer(
    $company_id: String!
    $datetime_to_customer: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDraftClaimsByCompanyIdByDatetimeToCustomer(
      company_id: $company_id
      datetime_to_customer: $datetime_to_customer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDraftClaimsByClaimStatusByDatetimeToCustomer = /* GraphQL */ `
  query GetDraftClaimsByClaimStatusByDatetimeToCustomer(
    $claim_status: String!
    $datetime_to_customer: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDraftClaimsByClaimStatusByDatetimeToCustomer(
      claim_status: $claim_status
      datetime_to_customer: $datetime_to_customer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDraftClaims = /* GraphQL */ `
  query SearchDraftClaims(
    $filter: SearchableDraftClaimFilterInput
    $sort: [SearchableDraftClaimSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDraftClaimAggregationInput]
  ) {
    searchDraftClaims(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      doc_type
      file_type
      URL
      PIO_URL
      uploaded_date
      user_ID
      customer_uuid
      company_id
      insured_entity_uuid
      draft_claim_id
      loss_uuid
      note
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doc_type
        file_type
        URL
        PIO_URL
        uploaded_date
        user_ID
        customer_uuid
        company_id
        insured_entity_uuid
        draft_claim_id
        loss_uuid
        note
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentByDraftClaimIdByCompanyId = /* GraphQL */ `
  query GetDocumentByDraftClaimIdByCompanyId(
    $draft_claim_id: String!
    $company_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDocumentByDraftClaimIdByCompanyId(
      draft_claim_id: $draft_claim_id
      company_id: $company_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doc_type
        file_type
        URL
        PIO_URL
        uploaded_date
        user_ID
        customer_uuid
        company_id
        insured_entity_uuid
        draft_claim_id
        loss_uuid
        note
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClaimLifecycle = /* GraphQL */ `
  query GetClaimLifecycle($id: ID!) {
    getClaimLifecycle(id: $id) {
      id
      previous_status
      update_status
      draft_claim_id
      user_id
      user
      practice
      event
      customer_uuid
      company_id
      insured_entity_uuid
      duration_in_status
      next_record_id
      previous_record_id
      step
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClaimLifecycles = /* GraphQL */ `
  query ListClaimLifecycles(
    $filter: ModelClaimLifecycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimLifecycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        previous_status
        update_status
        draft_claim_id
        user_id
        user
        practice
        event
        customer_uuid
        company_id
        insured_entity_uuid
        duration_in_status
        next_record_id
        previous_record_id
        step
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchClaimLifecycles = /* GraphQL */ `
  query SearchClaimLifecycles(
    $filter: SearchableClaimLifecycleFilterInput
    $sort: [SearchableClaimLifecycleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClaimLifecycleAggregationInput]
  ) {
    searchClaimLifecycles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        previous_status
        update_status
        draft_claim_id
        user_id
        user
        practice
        event
        customer_uuid
        company_id
        insured_entity_uuid
        duration_in_status
        next_record_id
        previous_record_id
        step
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDraftClaimArchive = /* GraphQL */ `
  query GetDraftClaimArchive($id: ID!) {
    getDraftClaimArchive(id: $id) {
      id
      start_date
      end_date
      draft_claim_id
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_return_message
      admin_notes
      manual_process_reason
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDraftClaimArchives = /* GraphQL */ `
  query ListDraftClaimArchives(
    $filter: ModelDraftClaimArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraftClaimArchives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDraftClaimArchives = /* GraphQL */ `
  query SearchDraftClaimArchives(
    $filter: SearchableDraftClaimArchiveFilterInput
    $sort: [SearchableDraftClaimArchiveSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDraftClaimArchiveAggregationInput]
  ) {
    searchDraftClaimArchives(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUtility = /* GraphQL */ `
  query GetUtility($id: ID!) {
    getUtility(id: $id) {
      id
      claim_amount
      show_claim_invoices
      show_claim_clinical_history
      show_claim_treatment_notes
      show_claim_paid_information
      send_request_consent_email
      send_review_claim_email
      send_claim_submitted_email
      vet_practice_email_override
      customer_email_override
      bbm_handler_email
      covea_handler_email
      notification_override
      cc_email_override
      show_remittance_information
      expiry_duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUtilities = /* GraphQL */ `
  query ListUtilities(
    $filter: ModelUtilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        claim_amount
        show_claim_invoices
        show_claim_clinical_history
        show_claim_treatment_notes
        show_claim_paid_information
        send_request_consent_email
        send_review_claim_email
        send_claim_submitted_email
        vet_practice_email_override
        customer_email_override
        bbm_handler_email
        covea_handler_email
        notification_override
        cc_email_override
        show_remittance_information
        expiry_duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGenericEmailContent = /* GraphQL */ `
  query GetGenericEmailContent($id: ID!) {
    getGenericEmailContent(id: $id) {
      id
      event_type
      vet_email_override
      vet_email_active
      vet_email_payload
      customer_email_override
      customer_email_active
      customer_email_payload
      admin_email_override
      admin_email_active
      admin_email_payload
      system_email_override
      system_email_payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGenericEmailContents = /* GraphQL */ `
  query ListGenericEmailContents(
    $filter: ModelGenericEmailContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenericEmailContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_type
        vet_email_override
        vet_email_active
        vet_email_payload
        customer_email_override
        customer_email_active
        customer_email_payload
        admin_email_override
        admin_email_active
        admin_email_payload
        system_email_override
        system_email_payload
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchGenericEmailContents = /* GraphQL */ `
  query SearchGenericEmailContents(
    $filter: SearchableGenericEmailContentFilterInput
    $sort: [SearchableGenericEmailContentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGenericEmailContentAggregationInput]
  ) {
    searchGenericEmailContents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        event_type
        vet_email_override
        vet_email_active
        vet_email_payload
        customer_email_override
        customer_email_active
        customer_email_payload
        admin_email_override
        admin_email_active
        admin_email_payload
        system_email_override
        system_email_payload
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEventLog = /* GraphQL */ `
  query GetEventLog($id: ID!) {
    getEventLog(id: $id) {
      id
      customer_id
      company_id
      user_id
      date_time
      data
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventLogs = /* GraphQL */ `
  query ListEventLogs(
    $filter: ModelEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        company_id
        user_id
        date_time
        data
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchEventLogs = /* GraphQL */ `
  query SearchEventLogs(
    $filter: SearchableEventLogFilterInput
    $sort: [SearchableEventLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventLogAggregationInput]
  ) {
    searchEventLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customer_id
        company_id
        user_id
        date_time
        data
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSubmittedClaim = /* GraphQL */ `
  query GetSubmittedClaim($id: ID!) {
    getSubmittedClaim(id: $id) {
      id
      customer_id
      practice_id
      loss_uuid
      loss_ref
      case_uuid
      case_ref
      policy_number
      archive_claim_id
      archive_claim {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      portal_claim_id
      portal_claim {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      document_ids
      paired_claim
      PIO_loss_status
      PIO_loss_outcome_time
      PIO_loss_created_date
      PIO_loss_updated_date
      PIO_loss_total
      PIO_payment_amount
      assigned_to
      createdAt
      updatedAt
      item_type
      __typename
    }
  }
`;
export const listSubmittedClaims = /* GraphQL */ `
  query ListSubmittedClaims(
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmittedClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByPracticeIdByLossRef = /* GraphQL */ `
  query GetSubmittedClaimByPracticeIdByLossRef(
    $practice_id: String!
    $loss_ref: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByPracticeIdByLossRef(
      practice_id: $practice_id
      loss_ref: $loss_ref
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByPracticeIdByPolicyNumber = /* GraphQL */ `
  query GetSubmittedClaimByPracticeIdByPolicyNumber(
    $practice_id: String!
    $policy_number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByPracticeIdByPolicyNumber(
      practice_id: $practice_id
      policy_number: $policy_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByPracticeIdByCreatedAt = /* GraphQL */ `
  query GetSubmittedClaimByPracticeIdByCreatedAt(
    $practice_id: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByPracticeIdByCreatedAt(
      practice_id: $practice_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByLossUUIDByCreatedAt = /* GraphQL */ `
  query GetSubmittedClaimByLossUUIDByCreatedAt(
    $loss_uuid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByLossUUIDByCreatedAt(
      loss_uuid: $loss_uuid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByLossRefByCreatedAt = /* GraphQL */ `
  query GetSubmittedClaimByLossRefByCreatedAt(
    $loss_ref: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByLossRefByCreatedAt(
      loss_ref: $loss_ref
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByPolicyNumberByCreatedAt = /* GraphQL */ `
  query GetSubmittedClaimByPolicyNumberByCreatedAt(
    $policy_number: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByPolicyNumberByCreatedAt(
      policy_number: $policy_number
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubmittedClaimByCreatedAt = /* GraphQL */ `
  query GetSubmittedClaimByCreatedAt(
    $item_type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubmittedClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmittedClaimByCreatedAt(
      item_type: $item_type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSubmittedClaims = /* GraphQL */ `
  query SearchSubmittedClaims(
    $filter: SearchableSubmittedClaimFilterInput
    $sort: [SearchableSubmittedClaimSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSubmittedClaimAggregationInput]
  ) {
    searchSubmittedClaims(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      title
      description
      tag
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        tag
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchFAQS = /* GraphQL */ `
  query SearchFAQS(
    $filter: SearchableFAQFilterInput
    $sort: [SearchableFAQSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFAQAggregationInput]
  ) {
    searchFAQS(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        description
        tag
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getStatsSourceStatus = /* GraphQL */ `
  query GetStatsSourceStatus($id: ID!) {
    getStatsSourceStatus(id: $id) {
      id
      company_id
      company_name
      draft_new
      draft_incomplete
      draft_complete
      draft_archived
      with_client
      client_approved
      auto_approved
      returned_by_client
      returned_by_admin
      archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStatsSourceStatuses = /* GraphQL */ `
  query ListStatsSourceStatuses(
    $filter: ModelStatsSourceStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatsSourceStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company_id
        company_name
        draft_new
        draft_incomplete
        draft_complete
        draft_archived
        with_client
        client_approved
        auto_approved
        returned_by_client
        returned_by_admin
        archived
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatsClaimsThroughHub = /* GraphQL */ `
  query GetStatsClaimsThroughHub($id: ID!) {
    getStatsClaimsThroughHub(id: $id) {
      id
      company_id
      company_name
      added
      moved_to_pio
      currently_in_hub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStatsClaimsThroughHubs = /* GraphQL */ `
  query ListStatsClaimsThroughHubs(
    $filter: ModelStatsClaimsThroughHubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatsClaimsThroughHubs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company_id
        company_name
        added
        moved_to_pio
        currently_in_hub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatsCycleTime = /* GraphQL */ `
  query GetStatsCycleTime($id: ID!) {
    getStatsCycleTime(id: $id) {
      id
      company_id
      company_name
      date_claim_created
      date_claim_closed
      duration_draft
      duration_with_client
      duration_returned_by_admin
      duration_returned_by_client
      duration_approved
      duration_archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStatsCycleTimes = /* GraphQL */ `
  query ListStatsCycleTimes(
    $filter: ModelStatsCycleTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatsCycleTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_id
        company_name
        date_claim_created
        date_claim_closed
        duration_draft
        duration_with_client
        duration_returned_by_admin
        duration_returned_by_client
        duration_approved
        duration_archived
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAugmentedDocuments = /* GraphQL */ `
  query GetAugmentedDocuments($id: ID!) {
    getAugmentedDocuments(id: $id) {
      id
      augmented_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAugmentedDocuments = /* GraphQL */ `
  query ListAugmentedDocuments(
    $filter: ModelAugmentedDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAugmentedDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        augmented_JSON
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRawDocuments = /* GraphQL */ `
  query GetRawDocuments($id: ID!) {
    getRawDocuments(id: $id) {
      id
      raw_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRawDocuments = /* GraphQL */ `
  query ListRawDocuments(
    $filter: ModelRawDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRawDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        raw_JSON
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogsDocuments = /* GraphQL */ `
  query GetLogsDocuments($id: ID!) {
    getLogsDocuments(id: $id) {
      id
      logs_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogsDocuments = /* GraphQL */ `
  query ListLogsDocuments(
    $filter: ModelLogsDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogsDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logs_JSON
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOCRLookup = /* GraphQL */ `
  query GetOCRLookup($id: ID!) {
    getOCRLookup(id: $id) {
      id
      doc_type
      doc_uuid
      customer_uuid
      case_uuid
      loss_uuid
      insured_entity_uuid
      policy_uuid
      raw_json_id
      aug_json_id
      log_json_id
      raw_json_url
      aug_json_url
      log_json_url
      file_uploaded_date
      file_name
      url
      csv_created_at
      ocr_stored_at
      ocr_augmented_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOCRLookups = /* GraphQL */ `
  query ListOCRLookups(
    $filter: ModelOCRLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOCRLookups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doc_type
        doc_uuid
        customer_uuid
        case_uuid
        loss_uuid
        insured_entity_uuid
        policy_uuid
        raw_json_id
        aug_json_id
        log_json_id
        raw_json_url
        aug_json_url
        log_json_url
        file_uploaded_date
        file_name
        url
        csv_created_at
        ocr_stored_at
        ocr_augmented_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
