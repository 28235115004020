import { zendesk } from '@boughtbymany/many-patterns'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead, VueHeadMixin } from '@unhead/vue'
import Amplify from 'aws-amplify'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import { createAuth0 } from '@/lib/auth0/auth0-proxy'
import { getMessages } from '@/lib/i18n'
import initSentry from '@/lib/sentry'

import awsconfig from './amplify'
import App from './App.vue'
/* eslint-disable */
/* eslint-enable */
import DataSchema from './DataSchema'
import router from './router'
import '@/config/vee-validate'

const env = import.meta.env.VITE_AMPLIFY_ENV ?? 'production'

Amplify.configure({
  ...awsconfig[env],
  aws_appsync_graphqlEndpoint: import.meta.env.VITE_GRAPHQL_URI,
})

const i18n = createI18n({
  locale: 'en-GB',
  messages: getMessages(),
  allowComposition: true,
  legacy: false,
})

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN || '',
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/`,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

// eslint-disable-next-line vue/require-name-property
const app = createApp(App)

const head = createHead()
app.mixin(VueHeadMixin)

// Configure Vue to use plugins and modules
app.use(router)
app.use(auth0) // Must load after router to make redirects work
app.use(pinia)
app.use(i18n)
app.use(VueQueryPlugin)
app.use(head)

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
// NOTE: dayjs.tz() still needs to be invoked to use the default timezone, otherwise it defaults to local.
// See issue: https://github.com/iamkun/dayjs/issues/1227
dayjs.tz.setDefault('Europe/London')

initSentry({ app, router })
DataSchema()

if (import.meta.env.VITE_ZENDESK) {
  app.use(zendesk, {
    key: import.meta.env.VITE_ZENDESK,
    disabled: true,
    settings: {
      webWidget: {
        chat: {
          connectOnPageLoad: false,
        },
        color: {
          theme: '#294638', // Widget outline
          launcher: '#294638', // Help button bkg
          launcherText: '#FFFFFF', // Help button text
          button: '#294638', // Inner widget button bkg
          resultLists: '#304858', // Results list text color
          header: '#294638', // Header bkg color
          articleLinks: '#304858', // Link color theme: '#DBDDDC',
        },
      },
    },
  })
}

router.isReady().then(() => app.mount('#app'))
