import { LossDocumentUnsaved, LossDocumentType } from '@/domain/pio/Loss'
import { Document } from '@/domain/vetPortal/Document'
import { mapS3UriToUrl, mapUrlToS3Uri } from '@/lib/utils/utils'

/**
 * @param {object} param
 * @param {Array} param.items an array of documents, probably from the draft claim or archive
 * @param {string} param.type a specific doc_type or null
 * @returns {Array} an array of objects with name as the doc url
 */
export const getUrlsByDocType = ({
  items,
  type,
}: {
  items: Document[]
  type: string
}) => {
  return (items || [])
    .filter(
      ({ doc_type, status }) =>
        (!doc_type || doc_type === type) &&
        (status === null || status === 'true')
    )
    .map(({ URL }) => ({ name: URL }))
}

export const documentLabel = (docType: string): LossDocumentType => {
  const labels = {
    invoice: 'INVOICE',
    treatmentHistory: 'MEDICAL_HISTORY',
    supportingDocs: 'OTHER',
    prescription: 'PRESCRIPTION',
    ReferringPracticeClinicalHistory: 'REFERRAL_REPORT',
    treatmentNote: 'TREATMENT_NOTE',
  } as { [key: string]: LossDocumentType }

  return labels[docType] || (docType as LossDocumentType)
}

export const prepareExistingDraftClaimFileNamesByDocType = ({
  items,
  type,
}: {
  items: Document[]
  type: string
}) => {
  return getUrlsByDocType({ items, type }).map(({ name }) => ({
    name: name.split('/').pop(),
    status: 'success',
  }))
}

export const formatClaimDocsForPio = ({
  documents,
}: {
  documents: Document[]
}): LossDocumentUnsaved[] => {
  return documents.map((item) => ({
    file_name: item.URL.split('/').pop() || '',
    mime: item.file_type,
    url: item.PIO_URL.startsWith('s3://')
      ? mapS3UriToUrl(item.PIO_URL) // converts for new claims
      : item.PIO_URL, // old draft claim was stored with URL
    s3_uri: item.PIO_URL.startsWith('s3://')
      ? item.PIO_URL // new claims are stored with S3 URI
      : mapUrlToS3Uri(item.PIO_URL), // old draft claim was stored with URL and needs to be converted to S3 URI
    type: documentLabel(item.doc_type),
    provided_by: 'VET',
  }))
}
