import dayjs from 'dayjs'

import { apiRequest, apiRequestAll } from '@/lib/api'
import { validateBySchema } from '@/lib/variables-type-validator'

import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

export const createDraftClaimArchive = async (input) => {
  try {
    const response = await apiRequest({
      query: mutations.createDraftClaimArchive,
      variables: { input },
    })

    return response?.data?.createDraftClaimArchive
  } catch (err) {
    const message = err.errors.reduce((acc, curr) => [...acc, curr.message], '')
    throw new Error(message.join(', '))
  }
}

export const updateDraftClaimArchive = async (input) => {
  try {
    const response = await apiRequest({
      query: mutations.updateDraftClaimArchive,
      variables: { input },
    })

    return response?.data?.updateDraftClaimArchive
  } catch (err) {
    const message = err.errors.reduce((acc, curr) => [...acc, curr.message], '')
    throw new Error(message.join(', '))
  }
}

export const getDraftClaimArchive = async ({ id }) => {
  const validation = validateBySchema('get_draft_claim_archive', {
    id,
  })

  if (!validation.status) {
    throw new Error(
      `Invalid get draft claim archive request: ${JSON.stringify(validation)}`
    )
  }

  const response = await apiRequest({
    query: queries.getDraftClaimArchive,
    variables: {
      id,
    },
  })

  return response.data.getDraftClaimArchive
}

/**
 * Return a list of draft claim archives.
 * @param {object} param A list of filters for the application
 * @param {number} param.limit
 * @param {string | string[]} param.companyId
 * @param {Array} param.statuses
 * @param {string} param.search
 * @param {string} param.nextToken
 * @param {object} param.sort
 * @param {boolean} param.all get all results
 * @param {string} param.assignedTo
 * @param {Date[]} param.treatmentDateRange
 */
export const searchDraftClaimArchives = async ({
  search,
  companyId,
  statuses,
  nextToken,
  limit = 1000,
  all,
  sort,
  assignedTo,
  treatmentDateRange,
}) => {
  const variables = {}
  const filters = []

  if (limit) {
    variables.limit = limit
  }

  if (Array.isArray(companyId)) {
    filters.push({
      or: companyId.map((item) => ({
        company_id: {
          eq: item,
        },
      })),
    })
  } else {
    filters.push({
      company_id: {
        eq: companyId,
      },
    })
  }

  if (statuses?.length > 0) {
    filters.push({
      or: statuses.map((status) => ({
        claim_status: {
          eq: status,
        },
      })),
    })
  }

  if (nextToken) {
    variables.nextToken = nextToken
  }

  if (search) {
    filters.push({
      or: [
        'vet_claim_ref',
        'hub_claim_ref',
        'bbm_policy_number',
        'family_name',
        'pet_name',
      ]
        .map((key) => [
          {
            [`${key}`]: {
              matchPhrase: search,
            },
          },
          {
            [`${key}`]: {
              wildcard: `*${search}*`,
            },
          },
        ])
        .flat(),
    })
  }

  if (treatmentDateRange && treatmentDateRange.length) {
    const startDate = dayjs(treatmentDateRange[0]).startOf('day').format()
    const endDate = dayjs(treatmentDateRange[1]).endOf('day').format()

    filters.push({
      start_date: {
        between: [startDate, endDate],
      },
    })
  }

  // We need to pass assignedTo as null sometimes
  if (assignedTo) {
    filters.push({
      admin_assigned_to_id: {
        eq: assignedTo,
      },
    })
  } else if (assignedTo === null) {
    filters.push({
      admin_assigned_to_id: {
        attributeExists: false,
      },
    })
  }

  if (filters.length) {
    variables.filter = {
      and: [...filters],
    }
  }

  variables.sort = { direction: 'desc', field: 'datetime_to_customer' }

  if (sort) {
    variables.sort = sort
  }

  const requestFunction = all ? apiRequestAll : apiRequest

  const response = await requestFunction({
    query: queries.searchDraftClaimArchives,
    variables,
  })

  if (all) {
    return { items: response }
  }

  return response.data?.searchDraftClaimArchives || {}
}

export default {
  createDraftClaimArchive,
  getDraftClaimArchive,
  searchDraftClaimArchives,
  updateDraftClaimArchive,
}
