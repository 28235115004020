import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('add_customer', {
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
    family_name: {
      type: 'String',
      required: true,
      errorMessage: 'family_name is required',
    },
  })
  createSchema('update_customer', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
  })
  createSchema('delete_customer', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
  })
}
