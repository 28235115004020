import lengthValidator from './length-validator'
import specialCharactersValidator from './special-characters-validator'
import supportFunction from './support'
import typeValidator from './type-validator'

/**
 *
 * @param variables
 */
export default function validate(variables) {
  try {
    let status = {
      status: true,
      message: 'all variables are valid',
    }

    for (const i in variables) {
      if (variables[i].value || variables[i].default) {
        variables[i] = supportFunction.rearrengeKey(variables[i])
        const valid = false
        const validatorResult = []
        const validatorMessages = []
        for (const key in variables[i]) {
          let result = {}
          switch (key) {
            case 'type':
              result = typeValidator(variables[i].value, variables[i].type, i)
              validatorMessages.push(result.message)
              validatorResult.push(result.result)
              break
            case 'required':
              result =
                variables[i].value === undefined ||
                variables[i].value === null ||
                variables[i].value === ''
                  ? {
                      result: false,
                      message: 'variable ' + i + ' cannot be null',
                    }
                  : { result: true, message: true }
              validatorMessages.push(result.message)
              validatorResult.push(result.result)
              break
            case 'length':
              result = lengthValidator(
                Object.keys(variables[i].value).length,
                variables[i].length,
                i
              )
              validatorMessages.push(result.message)
              validatorResult.push(result.result)
              break
            case 'restrictSpecialCharacters':
              result = specialCharactersValidator(
                variables[i].value,
                variables[i].restrictSpecialCharacters,
                variables[i].type,
                i
              )
              validatorMessages.push(result.message)
              validatorResult.push(result.result)
              break
            default:
          }
        }
        if (validatorResult.length > 0 && validatorResult.indexOf(false) > -1) {
          status = {
            status: false,
            message:
              variables[i].errorMessage ||
              validatorMessages[validatorResult.indexOf(false)],
          }
          break
        }
      } else {
        if (variables[i].required === false) {
          status = {
            status: true,
            message: 'all variables are valid',
          }
        } else {
          status = {
            status: false,
            message:
              variables[i].errorMessage || 'unable to found value for key ' + i,
          }
        }

        break
      }
    }
    return status
  } catch (err) {
    console.error(new Error(err))
  }
}
