import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('add_insured_entity', {
    policy_number: {
      type: 'String',
      required: true,
      errorMessage: 'policy_number is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
    bbm_insured_entity_uuid: {
      type: 'String',
      required: true,
      errorMessage: 'bbm_insured_entity_uuid is required',
    },
    family_name: {
      type: 'String',
      required: true,
      errorMessage: 'family_name is required',
    },
    pet_name: {
      type: 'String',
      required: true,
      errorMessage: 'pet_name is required',
    },
  })
  createSchema('update_insured_entity', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    bbm_insured_entity_uuid: {
      type: 'String',
      required: true,
      errorMessage: 'bbm_insured_entity_uuid is required',
    },
  })
  createSchema('delete_insured_entity', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    bbm_insured_entity_uuid: {
      type: 'String',
      required: true,
      errorMessage: 'bbm_insured_entity_uuid is required',
    },
  })
}
