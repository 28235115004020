import { validate as validateUuid } from 'uuid'

import { ListResponse } from '@/domain/API'
import {
  Claim,
  ClaimFormData,
  ClaimFormContinuationData,
} from '@/domain/pio/Claim'
import { SimpleRestApi, API } from '@/lib/api'
import { caseRegex, lossRegex } from '@/lib/regex'
import { isFulfilled } from '@/lib/utils/utils'
import { getPoliciesV2 } from '@/services/pio/policyService'

export const createClaim = async (
  data: ClaimFormData | ClaimFormContinuationData
): Promise<Claim> => {
  return new API()
    .post('POST_CLAIMS', {
      queryParams: {
        complete_claim: true,
      },
      body: data,
    })
    .catch((error) => {
      throw new Error(
        `${error.message}${
          error.response?.detail && `: ${error.response?.detail}`
        }`
      )
    })
}

export const createClaimContinuation = async (
  data: ClaimFormContinuationData
): Promise<Claim> => {
  return createClaim(data)
}

export const listClaims = async ({
  pageNumber,
  perPage,
  insuredEntityId,
}: {
  pageNumber?: number
  perPage?: number
  insuredEntityId?: string
} = {}): Promise<{
  items: Claim[]
  meta: { max_results: number; page: number; total: number }
}> => {
  const params = {} as {
    page_number?: number
    per_page?: number
    insured_entity_id?: string
  }

  if (pageNumber) {
    params.page_number = pageNumber
  }

  if (perPage) {
    params.per_page = perPage
  }

  if (insuredEntityId) {
    params.insured_entity_id = insuredEntityId
  }

  const res: ListResponse<Claim> = await new API().get('GET_CLAIMS', {
    queryParams: params,
  })

  return {
    items: res?._embedded?.items,
    meta: res?._meta,
  }
}

const formatSearch = (search: string) => {
  // the first los_ in a loss ref search is case sensitive and has
  // to be lower case, but the rest of the search can be case sensitive so we only format the beginning of the loss id

  return search.replace(/^(los[_ ])/i, 'los_')
}

interface searchClaimsAPIParams {
  page_number?: number
  per_page?: number
  vet_practice_id?: string
  source?: string
  q: string
  ordering: string
}

export const searchClaims = async ({
  pageNumber,
  perPage,
  vetPracticeId,
  source,
  search,
  practiceId,
}: {
  pageNumber?: number
  perPage?: number
  vetPracticeId?: string
  source?: string
  search?: string
  practiceId?: string
}) => {
  const params = {
    ordering: '-created_at',
  } as searchClaimsAPIParams

  if (pageNumber) {
    params.page_number = pageNumber
  }

  if (vetPracticeId) {
    params.vet_practice_id = vetPracticeId
  }

  if (source) {
    params.source = source
  }

  if (perPage || perPage === 0) {
    params.per_page = perPage
  }

  if (practiceId) {
    params.vet_practice_id = practiceId
  }

  if (search) {
    // If the search looks like a policy number,
    // we have to get the policy id first and use
    // that as the real search

    // PIO search supports case id, case ref, loss ref or policy uuid (not number)
    const searchParam = search

    const isCaseIdOrRef = caseRegex(search) === true
    const isLossIdOrRef = lossRegex(search) === true
    const isPolicyUuid = validateUuid(search) || search.match(/^pol_[\w]+$/)

    // A policy number can have two formats.
    // 1031268000297992 (cat-dog)
    // 3s7qmu (cat-dog-pio)
    const isPolicyNumber =
      search.match(/^[0-9-]{9,}$/) || search.match(/^[a-z0-9]{6}$/)

    // We don't support this search
    if (!(isCaseIdOrRef || isLossIdOrRef || isPolicyUuid || isPolicyNumber)) {
      throw new Error('Unsupported search term')
    }

    if (isPolicyNumber) {
      let policyNumber = search
      // We need to extend shorthand policy numbers into full ones
      if (
        (search.match(/^\d+$/) &&
          search.length < 10 &&
          !search.match(/^1031268/)) ||
        search.match(/^\d{3}-\d{3}-\d{3}$/)
      ) {
        policyNumber = `1031268${search.replaceAll('-', '')}`
      }

      const policy = await getPoliciesV2({
        ref: policyNumber,
      }).then((res) => res.items[0])

      if (!policy) {
        throw new Error('Could not identify policy')
      }

      // Search claims for each policy pet
      const searchPromises = await Promise.allSettled(
        policy.pets.map((pet) => {
          return simpleSearchClaims({ ...params, q: pet.policy_id })
        })
      )

      // Identify the successfully completed API calls
      const results = searchPromises
        .filter((promise) => isFulfilled<ListResponse<Claim>>(promise))
        .map((promise) => promise.value)

      // Reduce the meta data from the successful calls into one object
      const metaPage = results[0]._meta.page
      const metaTotal = results
        .map((item) => item._meta.total)
        .reduce((acc, curr) => {
          return acc + curr
        })
      const maxResultsTotal = results
        .map((item) => item._meta.max_results)
        .reduce((acc, curr) => {
          return acc + curr
        })

      return {
        items: results.map((response) => response._embedded.items).flat(),
        meta: {
          max_results: maxResultsTotal,
          page: metaPage,
          total: metaTotal,
        },
      }
    }

    params.q = formatSearch(searchParam)
  }

  return simpleSearchClaims(params).then((response) => {
    return {
      items: response._embedded.items,
      meta: response._meta,
    }
  })
}

const simpleSearchClaims = async (
  params: searchClaimsAPIParams
): Promise<ListResponse<Claim>> => {
  const res = await new SimpleRestApi().get(`/v4/claims/search`, {}, params)

  return await res.json()
}

export const getClaim = async (claimId: string): Promise<Claim> => {
  return await new API().get('GET_CLAIM', {
    pathOptions: { claim_id: claimId },
  })
}

export const changeVetListReference = async ({
  claimId,
  practiceId,
  newPracticeId,
}: {
  claimId: string
  practiceId: string
  newPracticeId: string
}) => {
  return new SimpleRestApi().post(
    `/v4/claims/${claimId}/replace-vet-practice-id`,
    {
      body: JSON.stringify({
        ...(practiceId ? { old_vet_practice_id: practiceId } : {}),
        new_vet_practice_id: newPracticeId,
      }),
    }
  )
}

export default {
  createClaim,
  getClaim,
}
