import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('add_claim', {
    claim_status: {
      type: 'String',
      required: true,
      errorMessage: 'claim_status is required',
    },
    // hub_claim_ref: {
    //     type: "number",
    //     required: true,
    //     errorMessage: "hub_claim_ref is required"
    // },
    claim_type: {
      type: 'String',
      required: true,
      errorMessage: 'claim_type is required',
    },
    bbm_policy_number: {
      type: 'String',
      required: true,
      errorMessage: 'policy_number is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    bbm_insured_entity_uuid: {
      type: 'String',
      required: true,
      errorMessage: 'bbm_insured_entity_uuid is required',
    },
    family_name: {
      type: 'String',
      required: true,
      errorMessage: 'family_name is required',
    },
    pet_name: {
      type: 'String',
      required: true,
      errorMessage: 'pet_name is required',
    },
  })

  createSchema('update_claim', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    claim_status: {
      type: 'String',
      required: true,
      errorMessage: 'claim_status is required',
    },
    // hub_claim_ref: {
    //     type: "number",
    //     required: true,
    //     errorMessage: "hub_claim_ref is required"
    // },
    claim_type: {
      type: 'String',
      required: true,
      errorMessage: 'claim_type is required',
    },
    bbm_policy_number: {
      type: 'String',
      required: true,
      errorMessage: 'policy_number is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    family_name: {
      type: 'String',
      required: true,
      errorMessage: 'family_name is required',
    },
    pet_name: {
      type: 'String',
      required: true,
      errorMessage: 'pet_name is required',
    },
  })

  createSchema('delete_claim', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
  })
}
