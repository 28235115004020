import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('get_draft_claim_archive', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
  })
}
