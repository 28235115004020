import addHubCustomer from './addHubCustomer'
import claimForm from './claimForm'
import customer from './customer'
import customerCompany from './customerCompany'
import customerInvite from './customerInvite'
import getDraftClaim from './getDraftClaim'
import getDraftClaimArchive from './getDraftClaimArchive'
import insuredEntities from './insuredEntities'
import submittedClaims from './submittedClaims'

export default () => {
  insuredEntities()
  claimForm()
  customer()
  customerCompany()
  customerInvite()
  getDraftClaim()
  getDraftClaimArchive()
  addHubCustomer()
  submittedClaims()
}
