export const isRejected = (
  input: PromiseSettledResult<unknown>
): input is PromiseRejectedResult => input.status === 'rejected'

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => {
  return input.status === 'fulfilled' && !!input.value
}

export function mapS3UriToUrl(s3Uri: string): string {
  return (
    s3Uri
      .replace(/^s3:/, 'https:')
      // replaces first '/' surrounded by alphanumeric characters with .s3.amazonaws.com/ using lookahead
      .replace(/(\w)(\/)(?=\w)/, '$1.s3.amazonaws.com/')
  )
}
export function mapUrlToS3Uri(url: string): string {
  return url
    .replace(/^https:/, 's3:')
    .replace('.s3.amazonaws.com', '')
    .replace('claim-docs.eu.policies.io', 'manypets-claims-docs-eu-prod')
}
