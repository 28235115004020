import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('add_customer_company', {
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
  })
  createSchema('update_customer_company', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
  })
  createSchema('delete_customer_company', {
    id: {
      type: 'String',
      required: true,
      errorMessage: 'id is required',
    },
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    customer_id: {
      type: 'String',
      required: true,
      errorMessage: 'customer_id is required',
    },
  })
}
