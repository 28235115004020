import dayjs from 'dayjs'

import type {
  ClaimFormData,
  ClaimFormContinuationData,
} from '@/domain/pio/Claim'
import {
  GenericLoss,
  LossDocumentUnsaved,
  LossFlag,
  LossPayeeFormData,
  VetFeesLossData,
} from '@/domain/pio/Loss'
import { Relationship } from '@/domain/pio/Payee'
import { PolicyV2 } from '@/domain/pio/Policy'
import { AccountDetails } from '@/domain/vetPortal/AccountDetails'
import { ClaimForm } from '@/domain/vetPortal/ClaimForm'
import { Company } from '@/domain/vetPortal/Company'
import { DraftClaim, HydratedDraftClaim } from '@/domain/vetPortal/DraftClaim'
import { HubUser, UserType } from '@/domain/vetPortal/HubUser'
import { decrypt } from '@/lib/crypto'
import { formatClaimDocsForPio } from '@/lib/documents'
import { formatDate } from '@/lib/format'
import {
  createDraftClaimArchive,
  searchDraftClaimArchives,
  updateDraftClaimArchive,
} from '@/services/draftClaimArchiveService'
import {
  updateDraftClaim,
  searchDraftClaims,
} from '@/services/draftClaimService'
import { createClaim, getClaim } from '@/services/pio/claimService'
import { getPoliciesV2, getPolicyV2 } from '@/services/pio/policyService'
import { createSubmittedClaim } from '@/services/submittedClaimsService'

import { isClaimsConsolidation } from './opsToggle'

/**
 * @param claimType
 * @param props
 * @param props.limit
 * @param props.search
 * @param props.companyId
 * @param props.nextToken
 * @param props.statuses
 * @param props.orderBy
 * @param props.orderDirection
 * @param props.order
 * @param props.order.field
 * @param props.order.direction
 * @param props.sort
 * @param props.sort.field
 * @param props.sort.direction
 * @param props.assignedTo
 * @param props.treatmentDateRange
 * @param props.claimStatusType
 */
async function searchClaims(
  claimType: string,
  {
    search = '',
    companyId = '',
    limit = 1000,
    nextToken = null,
    statuses = [],
    sort = { field: 'createdAt', direction: 'desc' },
    assignedTo = '',
    treatmentDateRange = [],
  }: {
    search: string
    companyId: string | string[]
    limit: number
    nextToken?: string | null
    statuses: string[]
    sort?: {
      field: string
      direction: 'asc' | 'desc'
    }
    assignedTo?: string
    treatmentDateRange?: Date[]
  }
) {
  if (!['Draft', 'Archive'].includes(claimType)) {
    throw new Error('Unknown search method provided')
  }

  if (claimType === 'Draft') {
    return searchDraftClaims({
      search,
      companyId,
      nextToken: nextToken ?? '',
      statuses,
      limit,
      all: false,
      sort,
      assignedTo,
      treatmentDateRange,
      claimStatusType: claimType,
    })
  }

  if (isClaimsConsolidation()) {
    return searchDraftClaims({
      search,
      companyId,
      nextToken: nextToken ?? '',
      statuses,
      limit,
      all: false,
      sort,
      assignedTo,
      treatmentDateRange,
      claimStatusType: claimType,
    })
  }

  return searchDraftClaimArchives({
    search,
    companyId,
    nextToken: nextToken ?? '',
    statuses,
    limit,
    all: false,
    sort,
    assignedTo,
    treatmentDateRange,
  })
}

/**
 * This method is a proxy to the searchClaim method, but explicitly named to only be used for Vet Practices.
 * This is because in this method, the 'companyId' is required, where as for claims handlers it is optional.
 * @param claimType
 * @param companyId
 * @param props
 * @param props.search
 * @param props.companyId
 * @param props.limit
 * @param props.nextToken
 * @param props.statuses
 * @param props.sort
 * @param props.sort.field
 * @param props.sort.direction
 * @param props.treatmentDateRange
 */
export async function searchClaimsAsVetPractice(
  claimType: string,
  companyId: string,
  {
    search = '',
    limit = 50,
    nextToken,
    statuses = [],
    sort = { field: 'createdAt', direction: 'desc' },
    treatmentDateRange = [],
  }: {
    search?: string
    limit: number
    statuses: string[]
    nextToken?: string | null
    sort?: {
      field: string
      direction: 'asc' | 'desc'
    }
    treatmentDateRange?: Date[]
  } = {
    search: '',
    limit: 50,
    nextToken: null,
    statuses: [],
    sort: { field: 'createdAt', direction: 'desc' },
  }
) {
  if (!companyId) {
    throw new Error('Company Id must be provided')
  }

  return searchClaims(claimType, {
    companyId,
    search,
    limit,
    nextToken,
    statuses,
    sort,
    treatmentDateRange,
  })
}

/**
 * This method is a proxy to the searchClaim method, but explicitly named to only be used for Claims Handlers.
 * This is because in this method, the 'companyId' is optional, where as for a practice it is required.
 * @param claimType
 * @param props
 * @param props.search
 * @param props.limit
 * @param props.companyId
 * @param props.nextToken
 * @param props.statuses
 * @param props.assignedTo
 * @param props.sort
 * @param props.sort.field
 * @param props.sort.direction
 * @param props.treatmentDateRange
 * @param props.treatementDateRange
 */
export async function searchClaimsAsClaimsHandler(
  claimType: string,
  {
    search = '',
    limit = 1000,
    companyId = '',
    nextToken,
    statuses = [],
    sort = { field: 'createdAt', direction: 'asc' },
    assignedTo = '',
    treatmentDateRange = [],
  }: {
    search?: string
    limit: number
    companyId?: string
    nextToken: string | null
    statuses: string[]
    assignedTo?: string
    treatmentDateRange?: Date[]
    sort?: {
      field: string
      direction: 'asc' | 'desc'
    }
  } = {
    search: '',
    limit: 50,
    companyId: '',
    nextToken: null,
    statuses: [],
    sort: { field: 'createdAt', direction: 'asc' },
  }
) {
  return searchClaims(claimType, {
    companyId,
    search,
    limit,
    nextToken,
    statuses,
    sort,
    assignedTo,
    treatmentDateRange,
  })
}

/**
 * @param props
 * @param props.caseId
 * @param props.claim
 * @param props.loss
 * @param props.process_method
 * @param props.manual_process_reason
 * @param props.paired_claim
 * @param props.user
 */
export async function archiveClaim({
  caseId,
  claim,
  loss,
  process_method,
  manual_process_reason = '',
  paired_claim = false,
  user,
}: {
  caseId: string
  claim: Record<string, any>
  loss: any
  process_method: string
  manual_process_reason?: string
  paired_claim?: boolean
  user: HubUser
}) {
  if (!user) {
    throw new Error('User not defined for claim archive')
  }

  const response = await getClaim(caseId)

  if (Object.keys(response).length > 0) {
    const input = {
      start_date: claim.start_date,
      end_date: claim.end_date,
      bbm_insured_entity_uuid: claim.bbm_insured_entity_uuid,
      company_id: claim.company_id,
      bbm_policy_number: claim.bbm_policy_number,
      family_name: claim.family_name,
      pet_name: claim.pet_name,
      pet_type: claim.pet_type,
      claim_amount: claim.claim_amount,
      condition: claim.condition,
      date_of_loss: claim.date_of_loss,
      continuation: claim.continuation,
      datetime_to_customer: claim.datetime_to_customer,
      advice_note: claim.advice_note,
      hub_claim_ref: claim.hub_claim_ref,
      vet_claim_ref: claim.vet_claim_ref,
      wru_exp_to_be_paid: claim.wru_exp_to_be_paid,
      is_this_a_referral: claim.is_this_a_referral,
      ref_practice_name: claim.ref_practice_name,
      claim_type: claim.claim_type,
      admin_assigned_to_id: claim.admin_assigned_to_id,
      returned_to_practice_count: claim.returned_to_practice_count,
      returned_to_practice_reason: claim.returned_to_practice_reason,
      admin_notes: claim.admin_notes,
      draft_claim_id: claim.id,
      claim_status: 'Live Claim',
      admin_submitted_by_id: user.id,
      process_method,
      manual_process_reason,
      claim_status_type: 'Archive',
      submitted_by_id: claim.submitted_by_id,
    }

    const createDraftClaimArchiveResp = await createDraftClaimArchive(input)

    const createSubmittedClaimResp = await createSubmittedClaim({
      customer_id: loss.owner,
      practice_id: claim.company_id,
      loss_uuid: loss.id,
      loss_ref: loss.ref,
      case_uuid: caseId,
      case_ref: response.ref,
      policy_number: claim.bbm_policy_number,
      archive_claim_id: createDraftClaimArchiveResp.id,
      portal_claim_id: claim.id,
      PIO_loss_total: claim.claim_amount,
      PIO_loss_status: loss.status,
      PIO_loss_created_date: loss.created_at,
      paired_claim: paired_claim ? 'true' : null,
    })

    await updateDraftClaimArchive({
      id: createDraftClaimArchiveResp.id,
      submitted_claim_id: createSubmittedClaimResp.id,
    })

    await updateDraftClaim({
      id: claim.id,
      payload: {
        draft_claim_id: claim.id,
        submitted_claim_id: createSubmittedClaimResp.id,
        claim_status: 'Live Claim',
        claim_status_type: 'Archive',
        admin_submitted_by_id: user.id,
        process_method,
        manual_process_reason,
        condition: claim.condition, // ensures the condition stays up to date in the draft claim table in case it has been changed when a continuation is processed.
      },
    })

    return true
  }
}

/**
 * Create the payload for v3 claims creation
 * @param params
 * @param {{}} params.draftClaim
 * @param {{}} params.companyDetails
 * @param {{}} params.paymentDetails
 * @param {{}} params.policy
 * @param {string} params.paymentMethod
 * @param {string} params.paymentType
 * @param {string} params.continuationId
 * @param {Array} params.documents
 * @param params.userType
 * @param params.flags
 * @returns {{}}
 */
export function createClaimPayload({
  draftClaim,
  companyDetails,
  documents,
  paymentDetails,
  policy,
  paymentMethod,
  paymentType,
  continuationId,
  userType,
  flags,
}: {
  draftClaim: Record<string, any>
  companyDetails: Record<string, any>
  documents: LossDocumentUnsaved[]
  paymentDetails: Record<string, any>
  policy: PolicyV2
  paymentMethod: string
  paymentType: Relationship
  userType: Array<UserType>
  continuationId?: string
  flags?: LossFlag[]
}): ClaimFormData | ClaimFormContinuationData {
  const productLine = policy.product_line
  if (!productLine) {
    throw new Error(`Unable to correctly identify policy`)
  }

  const pet = policy.pets.find(
    (pet) => pet.uuid === draftClaim.bbm_insured_entity_uuid
  )
  if (!pet) {
    throw new Error(`Pet not found on policy`)
  }

  const coverage = productLine === 'cat-dog' ? 'vet-fees' : 'vet_fees'

  const claimConditionItem = JSON.parse(draftClaim.condition)[0]

  const condition: {
    algoliaID: string
    group: string
    id: string
    section: string
    subgroup: string
  } = {
    algoliaID: claimConditionItem.algoliaID,
    group: claimConditionItem.conditionType,
    id: claimConditionItem.conditionId,
    section: claimConditionItem.conditionType,
    subgroup: claimConditionItem.conditionName,
  }

  if (claimConditionItem.conditionType === 'Custom') {
    condition.algoliaID = '154525001'
    condition.id = 'con_Pm872lV94n0gNbfKCW04xa'
  }

  const payee: LossPayeeFormData = {
    payee_type: paymentType,
    payment_method: 'MANUAL',
  }

  if (paymentType === 'VET') {
    if (!companyDetails.vet_practice_list_id) {
      throw new Error(
        `Vet practice list id not provided for claim ${draftClaim.id}`
      )
    }
    payee.payment_method = paymentMethod
    payee.account = {
      account_name: decrypt(paymentDetails.account_name),
      account_number: decrypt(paymentDetails.account_number),
      sort_code: decrypt(paymentDetails.sortcode),
    }
    payee.vet_practice_id = companyDetails.vet_practice_list_id
  }

  const lossTitle = `Vet Submitted claim - ${claimConditionItem.conditionName}`

  let additional_info = ''
  additional_info += draftClaim.advice_note
    ? `${draftClaim.advice_note}\n----------\n`
    : ''

  additional_info += `Treatment period: ${formatDate(
    draftClaim.start_date || draftClaim.date_of_loss
  )} to ${formatDate(draftClaim.end_date || draftClaim.date_of_loss)}\n`

  additional_info += claimConditionItem.conditionName
    ? `Condition: ${claimConditionItem.conditionName}\n`
    : ''

  additional_info += draftClaim.ref_practice_name
    ? `Referring practice: ${draftClaim.ref_practice_name}\n`
    : ''

  additional_info += draftClaim.vet_claim_ref
    ? `Draft claim ID: ${draftClaim.vet_claim_ref}`
    : ''

  const dataObj = {
    accident: {
      cause_of_loss:
        JSON.parse(draftClaim.condition)[0].accident === 'No'
          ? 'ILLNESS'
          : 'ACCIDENT',
      description: lossTitle,
    },
    additional_info,
  } as VetFeesLossData

  return {
    ...(continuationId && {
      continuation_id: continuationId,
    }),
    insured_entity_id: draftClaim.bbm_insured_entity_uuid,
    losses: [
      {
        amount: formatToPennies(draftClaim.claim_amount), // Loss stores claim amount as pennies
        condition,
        coverage,
        date_of_loss: dayjs(draftClaim.start_date).format('YYYY-MM-DD'),
        treatment_end_date: dayjs(draftClaim.end_date).format('YYYY-MM-DD'),
        data: dataObj,
        documents,
        payee,
        ...(companyDetails.vet_practice_list_id &&
          userType.includes('bbm_admin') && {
            vets: [
              {
                address_1: companyDetails.address1,
                practice_name: companyDetails.name,
                vet_practice_id: companyDetails.vet_practice_list_id,
              },
            ],
          }),
        ...(flags && {
          flags,
        }),
      },
    ],
    policy_sid: pet.policy_id,
    title: lossTitle,
  }
}

export const requestChangeToDraftClaim = async ({
  id,
  adminReturnMessage,
  returnedToPracticeReason,
  currentReturnedToPracticeCount = 0,
}: {
  id: string
  adminReturnMessage: string
  returnedToPracticeReason: string
  currentReturnedToPracticeCount?: number
}) => {
  if (!id) {
    throw new Error('Draft Claim Id not provided')
  }

  return updateDraftClaim({
    id,
    payload: {
      claim_status: 'ManyPets Query',
      admin_return_message: adminReturnMessage,
      returned_to_practice_reason: returnedToPracticeReason,
      returned_to_practice_count: currentReturnedToPracticeCount + 1,
    },
  })
}

/**
 * @param draftClaim
 * @param draftClaim.start_date
 * @param draftClaim.end_date
 * @param draftClaim.bbm_insured_entity_uuid
 * @param draftClaim.company_id
 * @param draftClaim.bbm_policy_number
 * @param draftClaim.family_name
 * @param draftClaim.pet_name
 * @param draftClaim.pet_type
 * @param draftClaim.claim_amount
 * @param draftClaim.condition
 * @param draftClaim.date_of_loss
 * @param draftClaim.continuation
 * @param draftClaim.datetime_to_customer
 * @param draftClaim.advice_note
 * @param draftClaim.hub_claim_ref
 * @param draftClaim.vet_claim_ref
 * @param draftClaim.wru_exp_to_be_paid
 * @param draftClaim.is_this_a_referral
 * @param draftClaim.ref_practice_name
 * @param draftClaim.claim_type
 * @param draftClaim.admin_assigned_to_id
 * @param draftClaim.returned_to_practice_count
 * @param draftClaim.returned_to_practice_reason
 * @param draftClaim.admin_notes
 * @param draftClaim.claim_status
 * @param draftClaim.draft_claim_id
 * @param draftClaim.id
 */
export const deleteClaimByUser = async (draftClaim: DraftClaim) => {
  const payload = {
    start_date: draftClaim.start_date,
    end_date: draftClaim.end_date,
    bbm_insured_entity_uuid: draftClaim.bbm_insured_entity_uuid,
    company_id: draftClaim.company_id,
    bbm_policy_number: draftClaim.bbm_policy_number,
    family_name: draftClaim.family_name,
    pet_name: draftClaim.pet_name,
    pet_type: draftClaim.pet_type,
    claim_amount: draftClaim.claim_amount,
    condition: draftClaim.condition,
    date_of_loss: draftClaim.date_of_loss || null, // Fallback needed because of bad old data
    continuation: draftClaim.continuation,
    datetime_to_customer: draftClaim.datetime_to_customer || new Date(), // Fallback needed because of bad old data
    advice_note: draftClaim.advice_note,
    hub_claim_ref: draftClaim.hub_claim_ref,
    vet_claim_ref: draftClaim.vet_claim_ref,
    wru_exp_to_be_paid: draftClaim.wru_exp_to_be_paid,
    is_this_a_referral: draftClaim.is_this_a_referral,
    ref_practice_name: draftClaim.ref_practice_name,
    claim_type: draftClaim.claim_type,
    admin_assigned_to_id: draftClaim.admin_assigned_to_id,
    returned_to_practice_count: draftClaim.returned_to_practice_count,
    returned_to_practice_reason: draftClaim.returned_to_practice_reason,
    admin_notes: draftClaim.admin_notes,
    claim_status: 'Deleted by User',
    draft_claim_id: draftClaim.id,
  }

  await createDraftClaimArchive(payload)

  await updateDraftClaim({
    id: draftClaim.id,
    payload: {
      claim_status: 'Deleted by User',
      claim_status_type: 'Deleted by User',
    },
  })

  return true
}

const formatToPennies = (price: string) => {
  const priceFloat = parseFloat(price)

  return Math.round(priceFloat * 100)
}

/**
 * @todo this function should be called behind an LD toggle
 * @param param0.draftClaim
 * @param param0.companyDetails
 * @param param0.paymentDetails
 * @param param0.paymentType
 * @param param0.draftClaim.draftClaim
 * @param param0.draftClaim.companyDetails
 * @param param0.draftClaim.paymentDetails
 * @param param0.draftClaim.continuationId
 * @param param0.draftClaim.claimFormDocuments
 * @param param0.draftClaim.userType
 * @param param0.draftClaim.flags
 * @param param0.continuationId
 * @returns
 */
export const processClaim = async ({
  draftClaim,
  companyDetails,
  paymentDetails,
  continuationId,
  userType,
  flags,
}: {
  draftClaim: HydratedDraftClaim
  companyDetails: Company
  paymentDetails: AccountDetails
  continuationId?: string
  claimFormDocuments?: Array<any>
  userType: Array<UserType>
  flags?: LossFlag[]
}) => {
  /**
   * This has been implemented due to a historic issue with draftClaim.hub_claim_ref values possibly being shared across multiple claims, due to the random generation of hub_claim_ref values.
   * This is only necessary while Portal only claims exist.
   */
  const unformattedDocuments = (draftClaim.documents?.items || []).filter(
    (item) => item.company_id === companyDetails.company_id
  )

  const claimFormDocuments = formatClaimDocsForPio({
    documents: unformattedDocuments,
  })
  const documents: Array<LossDocumentUnsaved> = []
  let policy: PolicyV2
  try {
    policy = await getPoliciesV2({
      ref: draftClaim.bbm_policy_number,
    }).then((res) => res?.items[0])

    policy = await getPolicyV2(policy.pets[0].policy_id, {
      policy_at_date: dayjs(draftClaim.date_of_loss).format('YYYY-MM-DD'),
    })

    const pet = policy.pets.find(
      (pet) => pet.uuid === draftClaim.bbm_insured_entity_uuid
    )
    if (!pet) {
      throw new Error(`Pet not found on policy`)
    }
  } catch (error: any) {
    throw new Error('E2006: Unable to get policy for claim', { cause: error })
  }

  if (!claimFormDocuments?.length) {
    throw new Error('E2007: Unable to get claim documents')
  }

  const invoiceDocuments = claimFormDocuments.filter(
    (doc: LossDocumentUnsaved) => doc.type === 'INVOICE'
  )

  const medicalHistoryDocuments = claimFormDocuments.filter(
    (doc: LossDocumentUnsaved) => doc.type === 'MEDICAL_HISTORY'
  )

  if (invoiceDocuments.length === 0 || medicalHistoryDocuments.length === 0) {
    /**
     * The console.error has been adding to assist in debugging why document uploads sometimes fail.
     */
    // eslint-disable-next-line
    console.error(claimFormDocuments)
    throw new Error('E2007: Not all documents have been provided')
  }

  claimFormDocuments?.forEach(async (file) => {
    const { file_name, mime, type, url, s3_uri } = file

    documents.push({
      file_name,
      mime,
      type,
      url,
      s3_uri,
      provided_by: 'VET',
    })
  })

  const payee = draftClaim.wru_exp_to_be_paid.toLowerCase()
  let paymentType: Relationship = 'VET'

  if (payee === 'policy holder') {
    paymentType = 'POLICYHOLDER'
  } else if (payee === 'other') {
    paymentType = 'OTHER'
  }

  try {
    const claimFormData = createClaimPayload({
      draftClaim,
      companyDetails,
      documents,
      paymentDetails,
      policy,
      paymentMethod: 'BANK_TRANSFER', // @todo The more I look at this, the more I think it's a redundant field for createClaimPayload and it should be a const inside the function instead for Vet Payee's
      paymentType,
      continuationId,
      userType,
      flags,
    })

    const claim = await createClaim(claimFormData)
    return claim
  } catch (error: any) {
    await updateDraftClaim({
      id: draftClaim.id,
      payload: {
        claim_status: 'Auto Approved',
        admin_notes: 'E2005: Unable to create claim automatically',
      },
    })

    throw new Error('E2005: Unable to create claim in PIO', { cause: error })
  }
}

export const processAndArchiveClaim = async ({
  claim,
  company,
  accountDetails,
  continuationId,
  user,
  note,
  flags,
}: {
  claim: HydratedDraftClaim
  company: Company
  accountDetails: AccountDetails
  continuationId?: string
  user: HubUser
  note?: string
  flags?: LossFlag[]
}) => {
  const pioClaim = await processClaim({
    draftClaim: {
      ...claim,
      ...(note && {
        advice_note: note,
      }),
    },
    companyDetails: company,
    paymentDetails: accountDetails,
    ...(continuationId && {
      continuationId,
    }),
    userType: user.user_type,
    flags,
  })

  const loss = pioClaim.losses[0]
  const caseId = loss.case_id

  await archiveClaim({
    caseId,
    claim,
    loss,
    process_method: 'Auto Processed',
    user,
  })

  return pioClaim
}

export const formatDraftClaimPayload = ({
  form,
  customer,
  companyId,
  userId,
  datetimeToCustomer,
}: {
  form: ClaimForm
  customer: {
    bbm_insured_entity_uuid: string
    policy_number?: string
    bbm_policy_number?: string
    family_name: string
    pet_name: string
    pet_type: string
  }
  companyId: string
  userId: string
  datetimeToCustomer?: Date
}) => {
  const currentTime = dayjs.tz().format('hh:mm')
  return {
    ...(form.claimStartDate?.length > 0 && {
      start_date: dayjs(form.claimStartDate).tz().toISOString(),
    }),
    ...(form.claimEndDate?.length > 0 && {
      end_date: dayjs(form.claimEndDate).tz().toISOString(),
    }),
    bbm_insured_entity_uuid: customer.bbm_insured_entity_uuid,
    company_id: companyId,
    bbm_policy_number: customer.policy_number || customer.bbm_policy_number,
    family_name: customer.family_name,
    pet_name: customer.pet_name,
    pet_type: customer.pet_type,
    claim_amount: form.claimAmount,
    condition: JSON.stringify([form.condition]),
    date_of_loss:
      form.claimStartDate &&
      dayjs(form.claimStartDate + 'T' + currentTime).toISOString(),
    continuation: form.claimType === 'Continuation',
    claim_status: form.claimStatus, // @TODO handle PENDING for straightTrhoughProcess && submitting
    claim_status_type: 'Draft',
    datetime_to_customer: datetimeToCustomer,
    advice_note: form.adviceNote,
    hub_claim_ref: form.hubClaimRef,
    vet_claim_ref: form.vetClaimRef,
    wru_exp_to_be_paid: form.payee,
    is_this_a_referral: form.referral,
    ref_practice_name: form.referralPracticeName,
    claim_type:
      form.claimType === 'Continuation' ? 'Vet-Continuation' : 'Vet-Standard',
    submitted_by_id: userId,
  }
}

export const isPayableToVet = (loss: GenericLoss) => {
  return loss.payee?.payee_type === 'VET'
}

// Get the amount paid as a string so it can be formatted.
// The payment details API will return an empty string or 0 if the amount is 0,
// but undefined if it has not been paid yet.
export const getPaid = (amount?: string) => {
  if (amount === '') {
    return '0'
  }

  return amount || ''
}

export default {
  archiveClaim,
  createClaim,
  isPayableToVet,
  getPaid,
}
