import { apiRequest, apiRequestAll } from '@/lib/api'

import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

export const getSubmittedClaimsForPracticeByFilter = async ({
  practiceId,
  limit = 1000,
  queryBy,
  queryValue,
}) => {
  if (!practiceId) {
    return []
  }

  if (!queryBy || !queryValue) {
    return []
  }

  const variables = {
    practice_id: practiceId,
    limit,
  }

  let queryKey = ''
  if (queryBy === 'loss_ref') {
    queryKey = 'getSubmittedClaimByPracticeIdByLossRef'
    variables.loss_ref = { eq: queryValue }
  }

  if (queryBy === 'policy_number') {
    queryKey = 'getSubmittedClaimByPracticeIdByPolicyNumber'
    variables.policy_number = { eq: queryValue }
  }

  if (!queryKey) {
    return []
  }

  const response = await apiRequest({
    query: queries[queryKey],
    variables,
  })

  return response.data?.[queryKey] || {}
}

/**
 * @param {object} param An object of  variables
 * @param {number} param.limit the max number of results
 * @param {string} param.queryBy the property to query by
 * @param {string} param.queryValue the value to query
 * @param {string} param.nextToken
 * @returns {Promise} a promise with an array of items on resolve
 */
export const getSubmittedClaimsByCreatedAt = async ({
  limit = 1000,
  queryBy,
  queryValue,
  nextToken,
}) => {
  const variables = {
    limit,
    sortDirection: 'DESC',
    item_type: 'SubmittedClaim',
  }

  const validQueries = {
    loss_ref: 'getSubmittedClaimByLossRefByCreatedAt',
    policy_number: 'getSubmittedClaimByPolicyNumberByCreatedAt',
  }

  const queryKey = validQueries[queryBy] || 'getSubmittedClaimByCreatedAt'

  variables[queryBy] = queryValue

  if (nextToken) {
    variables.nextToken = nextToken
  }

  const response = await apiRequest({
    query: queries[queryKey],
    variables,
  })

  return response.data?.[queryKey] || {}
}

/**
 * Get Submitted claims for a practice, order by created date.
 * @param {object} param parameters for querying submitted claims by practice
 * @param {string} param.practiceId The practice Id
 * @param {number} [param.limit] The query limit
 * @param {string} [param.nextToken] The pagination token
 * @param {boolean} [param.all] whether to get all the claims
 * @returns {object} containing a prop of items with the submitted claims array
 */
export const getSubmittedClaimsForPractice = async ({
  practiceId,
  limit = 1000,
  nextToken = null,
  all = false,
} = {}) => {
  if (!practiceId) {
    return { items: [] }
  }

  const variables = {
    practice_id: practiceId,
    sortDirection: 'DESC',
    limit,
    ...(nextToken && { nextToken }),
  }

  const requestFunction = all ? apiRequestAll : apiRequest

  const response = await requestFunction({
    query: queries.getSubmittedClaimByPracticeIdByCreatedAt,
    variables,
  })

  if (all) {
    return { items: response }
  }

  return (
    response.data?.getSubmittedClaimByPracticeIdByCreatedAt || { items: [] }
  )
}

/**
 * Return a list of submitted claims.
 * @param {object} param A list of filters for the application
 * @param {number} param.limit
 * @param {string} param.search
 * @param {string} param.policyNumber
 * @param {string} param.companyId
 * @param {Array} param.pioLossStatuses
 * @param {string} param.nextToken
 * @param {boolean} param.all get all results
 * @param {string} param.archiveClaimId
 * @param {string[]} param.lossRefs
 */
export const searchSubmittedClaims = async ({
  limit = 1000,
  search,
  companyId,
  pioLossStatuses,
  policyNumber,
  archiveClaimId,
  lossRefs,
  nextToken,
  all,
}) => {
  const variables = {}
  const filters = []

  if (limit) {
    variables.limit = limit
  }

  if (nextToken) {
    variables.nextToken = nextToken
  }

  if (search) {
    filters.push({
      or: ['loss_uuid', 'loss_ref', 'case_uuid', 'case_ref', 'policy_number']
        .map((key) => [
          {
            [`${key}`]: {
              matchPhrase: search,
            },
          },
          {
            [`${key}`]: {
              wildcard: `*${search}*`,
            },
          },
        ])
        .flat(),
    })
  }

  if (lossRefs) {
    if (Array.isArray(lossRefs)) {
      filters.push({
        or: lossRefs.map((item) => ({
          loss_ref: {
            eq: item,
          },
        })),
      })
    }
  }

  if (policyNumber) {
    filters.push({
      policy_number: {
        eq: policyNumber,
      },
    })
  }

  if (companyId) {
    filters.push({
      practice_id: {
        eq: companyId,
      },
    })
  }

  if (archiveClaimId) {
    filters.push({
      archive_claim_id: {
        eq: archiveClaimId,
      },
    })
  }

  if (pioLossStatuses?.length > 0) {
    filters.push({
      or: pioLossStatuses.map((status) => ({
        PIO_loss_status: {
          eq: status,
        },
      })),
    })
  }

  if (filters.length) {
    variables.filter = {
      and: [...filters],
    }
  }

  variables.sort = { direction: 'desc', field: 'createdAt' }

  const requestFunction = all ? apiRequestAll : apiRequest

  const response = await requestFunction({
    query: queries.searchSubmittedClaims,
    variables,
  })

  if (all) {
    return { items: response }
  }

  return response.data?.searchSubmittedClaims || {}
}

export const createSubmittedClaim = async (input) => {
  try {
    const response = await apiRequest({
      query: mutations.createSubmittedClaim,
      variables: { input },
    })

    return response?.data?.createSubmittedClaim
  } catch (err) {
    const message = err.errors.reduce((acc, curr) => [...acc, curr.message], '')
    throw new Error(message.join(', '))
  }
}

export default {
  createSubmittedClaim,
}
