import { has, isEmpty } from 'lodash-es'

export const updateFilters = (
  filters: Record<string, any>,
  query: Record<string, any>,
  dates?: Array<string | null> | undefined
): Record<string, any> => {
  for (const key in filters) {
    filters[key] = ''
    if (has(query, key) && query[key]) {
      filters[key] = key !== 'dates' ? query[key] : formattedDates(dates)
    }
  }

  return filters
}

export const formattedDates = (
  dates: Array<string | null> | undefined
): Array<Date> | undefined => {
  const formattedDates: Array<Date> = []

  if (dates) {
    dates.forEach((date) => {
      if (date) {
        const dateArray = date.split('/')
        formattedDates.push(
          new Date(
            parseInt(dateArray[2]),
            parseInt(dateArray[1]) - 1,
            parseInt(dateArray[0])
          )
        )
      }
    })
  }

  return !isEmpty(formattedDates) ? formattedDates : undefined
}

export const submittedClaimsFilters = ({
  query,
}: {
  query?: { [key: string]: any }
}) => {
  return {
    search: query?.search as string | undefined,
  }
}

export const practiceFilters = ({
  query,
}: {
  query: { [key: string]: any }
}) => {
  const status = query?.status as string | undefined
  return {
    search: query?.search as string | undefined,
    filterDuplicates: status !== 'DUPLICATE',
    isDuplicate: status === 'DUPLICATE',
    status: status === 'DUPLICATE' ? undefined : status,
  }
}
