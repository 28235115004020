import { createSchema } from '@/lib/variables-type-validator'

export default () => {
  createSchema('add_hub_customer', {
    company_id: {
      type: 'String',
      required: true,
      errorMessage: 'company_id is required',
    },
    bbm_customer_uuid: {
      type: 'String',
      required: true,
      errorMessage: 'bbm_customer_uuid is required',
    },
    user_id: {
      type: 'String',
      required: true,
      errorMessage: 'user_id is required',
    },
    customer_name: {
      type: 'String',
      required: true,
      errorMessage: 'customer_name is required',
    },
    user_name: {
      type: 'String',
      required: true,
      errorMessage: 'user_name is required',
    },
    company_name: {
      type: 'String',
      required: true,
      errorMessage: 'company_name is required',
    },
  })
}
