/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHubUsers = /* GraphQL */ `
  mutation CreateHubUsers(
    $input: CreateHubUsersInput!
    $condition: ModelHubUsersConditionInput
  ) {
    createHubUsers(input: $input, condition: $condition) {
      id
      user_type
      video_vet_id
      video_vet_name
      company
      first_name
      last_name
      job_title
      role
      mobile
      verified_email
      password
      auth0_sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHubUsers = /* GraphQL */ `
  mutation UpdateHubUsers(
    $input: UpdateHubUsersInput!
    $condition: ModelHubUsersConditionInput
  ) {
    updateHubUsers(input: $input, condition: $condition) {
      id
      user_type
      video_vet_id
      video_vet_name
      company
      first_name
      last_name
      job_title
      role
      mobile
      verified_email
      password
      auth0_sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHubUsers = /* GraphQL */ `
  mutation DeleteHubUsers(
    $input: DeleteHubUsersInput!
    $condition: ModelHubUsersConditionInput
  ) {
    deleteHubUsers(input: $input, condition: $condition) {
      id
      user_type
      video_vet_id
      video_vet_name
      company
      first_name
      last_name
      job_title
      role
      mobile
      verified_email
      password
      auth0_sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegData = /* GraphQL */ `
  mutation CreateRegData(
    $input: CreateRegDataInput!
    $condition: ModelRegDataConditionInput
  ) {
    createRegData(input: $input, condition: $condition) {
      id
      company_id
      company_name
      company_address1
      company_address2
      company_address3
      company_town
      company_country
      company_post_code
      company_phone
      company_website
      company_group
      company_email
      company_email_validated
      company_invite_string
      company_invite_current
      first_name
      last_name
      job_title
      role
      mobile
      password
      email
      email_validated
      invite_URL
      invite_current
      createdAt
      updatedAt
      company {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      regDataCompanyId
      __typename
    }
  }
`;
export const updateRegData = /* GraphQL */ `
  mutation UpdateRegData(
    $input: UpdateRegDataInput!
    $condition: ModelRegDataConditionInput
  ) {
    updateRegData(input: $input, condition: $condition) {
      id
      company_id
      company_name
      company_address1
      company_address2
      company_address3
      company_town
      company_country
      company_post_code
      company_phone
      company_website
      company_group
      company_email
      company_email_validated
      company_invite_string
      company_invite_current
      first_name
      last_name
      job_title
      role
      mobile
      password
      email
      email_validated
      invite_URL
      invite_current
      createdAt
      updatedAt
      company {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      regDataCompanyId
      __typename
    }
  }
`;
export const deleteRegData = /* GraphQL */ `
  mutation DeleteRegData(
    $input: DeleteRegDataInput!
    $condition: ModelRegDataConditionInput
  ) {
    deleteRegData(input: $input, condition: $condition) {
      id
      company_id
      company_name
      company_address1
      company_address2
      company_address3
      company_town
      company_country
      company_post_code
      company_phone
      company_website
      company_group
      company_email
      company_email_validated
      company_invite_string
      company_invite_current
      first_name
      last_name
      job_title
      role
      mobile
      password
      email
      email_validated
      invite_URL
      invite_current
      createdAt
      updatedAt
      company {
        id
        name
        address1
        address2
        address3
        town
        county
        country
        post_code
        phone
        website
        company_id
        group
        verified_email
        direct_payment_preferred
        remittance_email
        vat_number
        claims_handling_email
        claims_handling_contact_name
        remittance_contact_name
        practice_manager_name
        custom_1
        custom_2
        custom_3
        cat_dog_vet_algolia_id
        pio_vet_algolia_id
        createdAt
        updatedAt
        status
        registered_date
        first_claim_date
        latest_claim_date
        vet_practice_list_id
        type
        __typename
      }
      regDataCompanyId
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      address3
      town
      county
      country
      post_code
      phone
      website
      company_id
      group
      verified_email
      direct_payment_preferred
      remittance_email
      vat_number
      claims_handling_email
      claims_handling_contact_name
      remittance_contact_name
      practice_manager_name
      custom_1
      custom_2
      custom_3
      cat_dog_vet_algolia_id
      pio_vet_algolia_id
      createdAt
      updatedAt
      status
      registered_date
      first_claim_date
      latest_claim_date
      vet_practice_list_id
      type
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      address3
      town
      county
      country
      post_code
      phone
      website
      company_id
      group
      verified_email
      direct_payment_preferred
      remittance_email
      vat_number
      claims_handling_email
      claims_handling_contact_name
      remittance_contact_name
      practice_manager_name
      custom_1
      custom_2
      custom_3
      cat_dog_vet_algolia_id
      pio_vet_algolia_id
      createdAt
      updatedAt
      status
      registered_date
      first_claim_date
      latest_claim_date
      vet_practice_list_id
      type
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      address1
      address2
      address3
      town
      county
      country
      post_code
      phone
      website
      company_id
      group
      verified_email
      direct_payment_preferred
      remittance_email
      vat_number
      claims_handling_email
      claims_handling_contact_name
      remittance_contact_name
      practice_manager_name
      custom_1
      custom_2
      custom_3
      cat_dog_vet_algolia_id
      pio_vet_algolia_id
      createdAt
      updatedAt
      status
      registered_date
      first_claim_date
      latest_claim_date
      vet_practice_list_id
      type
      __typename
    }
  }
`;
export const createAccountDetails = /* GraphQL */ `
  mutation CreateAccountDetails(
    $input: CreateAccountDetailsInput!
    $condition: ModelAccountDetailsConditionInput
  ) {
    createAccountDetails(input: $input, condition: $condition) {
      id
      user_id
      company_id
      account_name
      sortcode
      account_number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccountDetails = /* GraphQL */ `
  mutation UpdateAccountDetails(
    $input: UpdateAccountDetailsInput!
    $condition: ModelAccountDetailsConditionInput
  ) {
    updateAccountDetails(input: $input, condition: $condition) {
      id
      user_id
      company_id
      account_name
      sortcode
      account_number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccountDetails = /* GraphQL */ `
  mutation DeleteAccountDetails(
    $input: DeleteAccountDetailsInput!
    $condition: ModelAccountDetailsConditionInput
  ) {
    deleteAccountDetails(input: $input, condition: $condition) {
      id
      user_id
      company_id
      account_name
      sortcode
      account_number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerCompany = /* GraphQL */ `
  mutation CreateCustomerCompany(
    $input: CreateCustomerCompanyInput!
    $condition: ModelCustomerCompanyConditionInput
  ) {
    createCustomerCompany(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerCompany = /* GraphQL */ `
  mutation UpdateCustomerCompany(
    $input: UpdateCustomerCompanyInput!
    $condition: ModelCustomerCompanyConditionInput
  ) {
    updateCustomerCompany(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerCompany = /* GraphQL */ `
  mutation DeleteCustomerCompany(
    $input: DeleteCustomerCompanyInput!
    $condition: ModelCustomerCompanyConditionInput
  ) {
    deleteCustomerCompany(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      email
      customer_id
      family_name
      opt_out
      vet_added
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      email
      customer_id
      family_name
      opt_out
      vet_added
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      email
      customer_id
      family_name
      opt_out
      vet_added
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInsuredEntity = /* GraphQL */ `
  mutation CreateInsuredEntity(
    $input: CreateInsuredEntityInput!
    $condition: ModelInsuredEntityConditionInput
  ) {
    createInsuredEntity(input: $input, condition: $condition) {
      id
      bbm_insured_entity_uuid
      policy_number
      policy_status
      start_date
      end_date
      company_id
      customer_id
      pet_name
      pet_type
      post_code
      family_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInsuredEntity = /* GraphQL */ `
  mutation UpdateInsuredEntity(
    $input: UpdateInsuredEntityInput!
    $condition: ModelInsuredEntityConditionInput
  ) {
    updateInsuredEntity(input: $input, condition: $condition) {
      id
      bbm_insured_entity_uuid
      policy_number
      policy_status
      start_date
      end_date
      company_id
      customer_id
      pet_name
      pet_type
      post_code
      family_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInsuredEntity = /* GraphQL */ `
  mutation DeleteInsuredEntity(
    $input: DeleteInsuredEntityInput!
    $condition: ModelInsuredEntityConditionInput
  ) {
    deleteInsuredEntity(input: $input, condition: $condition) {
      id
      bbm_insured_entity_uuid
      policy_number
      policy_status
      start_date
      end_date
      company_id
      customer_id
      pet_name
      pet_type
      post_code
      family_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomersInvite = /* GraphQL */ `
  mutation CreateCustomersInvite(
    $input: CreateCustomersInviteInput!
    $condition: ModelCustomersInviteConditionInput
  ) {
    createCustomersInvite(input: $input, condition: $condition) {
      id
      customer_id
      user_id
      company_id
      policy_number
      email
      customer_full_name
      user_name
      company_name
      expiry_time_date
      info_only {
        status
        invite_string
        invite_current
        __typename
      }
      info_and_claim {
        status
        invite_string
        invite_current
        __typename
      }
      block_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      block_all_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      invite_current
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomersInvite = /* GraphQL */ `
  mutation UpdateCustomersInvite(
    $input: UpdateCustomersInviteInput!
    $condition: ModelCustomersInviteConditionInput
  ) {
    updateCustomersInvite(input: $input, condition: $condition) {
      id
      customer_id
      user_id
      company_id
      policy_number
      email
      customer_full_name
      user_name
      company_name
      expiry_time_date
      info_only {
        status
        invite_string
        invite_current
        __typename
      }
      info_and_claim {
        status
        invite_string
        invite_current
        __typename
      }
      block_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      block_all_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      invite_current
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomersInvite = /* GraphQL */ `
  mutation DeleteCustomersInvite(
    $input: DeleteCustomersInviteInput!
    $condition: ModelCustomersInviteConditionInput
  ) {
    deleteCustomersInvite(input: $input, condition: $condition) {
      id
      customer_id
      user_id
      company_id
      policy_number
      email
      customer_full_name
      user_name
      company_name
      expiry_time_date
      info_only {
        status
        invite_string
        invite_current
        __typename
      }
      info_and_claim {
        status
        invite_string
        invite_current
        __typename
      }
      block_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      block_all_vet_practice {
        status
        invite_string
        invite_current
        __typename
      }
      invite_current
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDraftClaim = /* GraphQL */ `
  mutation CreateDraftClaim(
    $input: CreateDraftClaimInput!
    $condition: ModelDraftClaimConditionInput
  ) {
    createDraftClaim(input: $input, condition: $condition) {
      id
      start_date
      end_date
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      draft_claim_id
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      admin_return_message
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_notes
      manual_process_reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDraftClaim = /* GraphQL */ `
  mutation UpdateDraftClaim(
    $input: UpdateDraftClaimInput!
    $condition: ModelDraftClaimConditionInput
  ) {
    updateDraftClaim(input: $input, condition: $condition) {
      id
      start_date
      end_date
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      draft_claim_id
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      admin_return_message
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_notes
      manual_process_reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDraftClaim = /* GraphQL */ `
  mutation DeleteDraftClaim(
    $input: DeleteDraftClaimInput!
    $condition: ModelDraftClaimConditionInput
  ) {
    deleteDraftClaim(input: $input, condition: $condition) {
      id
      start_date
      end_date
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      draft_claim_id
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      admin_return_message
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_notes
      manual_process_reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      doc_type
      file_type
      URL
      PIO_URL
      uploaded_date
      user_ID
      customer_uuid
      company_id
      insured_entity_uuid
      draft_claim_id
      loss_uuid
      note
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      doc_type
      file_type
      URL
      PIO_URL
      uploaded_date
      user_ID
      customer_uuid
      company_id
      insured_entity_uuid
      draft_claim_id
      loss_uuid
      note
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      doc_type
      file_type
      URL
      PIO_URL
      uploaded_date
      user_ID
      customer_uuid
      company_id
      insured_entity_uuid
      draft_claim_id
      loss_uuid
      note
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClaimLifecycle = /* GraphQL */ `
  mutation CreateClaimLifecycle(
    $input: CreateClaimLifecycleInput!
    $condition: ModelClaimLifecycleConditionInput
  ) {
    createClaimLifecycle(input: $input, condition: $condition) {
      id
      previous_status
      update_status
      draft_claim_id
      user_id
      user
      practice
      event
      customer_uuid
      company_id
      insured_entity_uuid
      duration_in_status
      next_record_id
      previous_record_id
      step
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClaimLifecycle = /* GraphQL */ `
  mutation UpdateClaimLifecycle(
    $input: UpdateClaimLifecycleInput!
    $condition: ModelClaimLifecycleConditionInput
  ) {
    updateClaimLifecycle(input: $input, condition: $condition) {
      id
      previous_status
      update_status
      draft_claim_id
      user_id
      user
      practice
      event
      customer_uuid
      company_id
      insured_entity_uuid
      duration_in_status
      next_record_id
      previous_record_id
      step
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClaimLifecycle = /* GraphQL */ `
  mutation DeleteClaimLifecycle(
    $input: DeleteClaimLifecycleInput!
    $condition: ModelClaimLifecycleConditionInput
  ) {
    deleteClaimLifecycle(input: $input, condition: $condition) {
      id
      previous_status
      update_status
      draft_claim_id
      user_id
      user
      practice
      event
      customer_uuid
      company_id
      insured_entity_uuid
      duration_in_status
      next_record_id
      previous_record_id
      step
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDraftClaimArchive = /* GraphQL */ `
  mutation CreateDraftClaimArchive(
    $input: CreateDraftClaimArchiveInput!
    $condition: ModelDraftClaimArchiveConditionInput
  ) {
    createDraftClaimArchive(input: $input, condition: $condition) {
      id
      start_date
      end_date
      draft_claim_id
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_return_message
      admin_notes
      manual_process_reason
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDraftClaimArchive = /* GraphQL */ `
  mutation UpdateDraftClaimArchive(
    $input: UpdateDraftClaimArchiveInput!
    $condition: ModelDraftClaimArchiveConditionInput
  ) {
    updateDraftClaimArchive(input: $input, condition: $condition) {
      id
      start_date
      end_date
      draft_claim_id
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_return_message
      admin_notes
      manual_process_reason
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDraftClaimArchive = /* GraphQL */ `
  mutation DeleteDraftClaimArchive(
    $input: DeleteDraftClaimArchiveInput!
    $condition: ModelDraftClaimArchiveConditionInput
  ) {
    deleteDraftClaimArchive(input: $input, condition: $condition) {
      id
      start_date
      end_date
      draft_claim_id
      bbm_insured_entity_uuid
      company_id
      bbm_policy_number
      family_name
      pet_name
      pet_type
      claim_amount
      condition
      date_of_loss
      continuation
      claim_status
      claim_status_type
      submitted_claim_id
      submitted_claim {
        id
        customer_id
        practice_id
        loss_uuid
        loss_ref
        case_uuid
        case_ref
        policy_number
        archive_claim_id
        archive_claim {
          id
          start_date
          end_date
          draft_claim_id
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_return_message
          admin_notes
          manual_process_reason
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        portal_claim_id
        portal_claim {
          id
          start_date
          end_date
          bbm_insured_entity_uuid
          company_id
          bbm_policy_number
          draft_claim_id
          family_name
          pet_name
          pet_type
          claim_amount
          condition
          date_of_loss
          continuation
          claim_status
          claim_status_type
          submitted_claim_id
          submitted_claim {
            id
            customer_id
            practice_id
            loss_uuid
            loss_ref
            case_uuid
            case_ref
            policy_number
            archive_claim_id
            archive_claim {
              id
              start_date
              end_date
              draft_claim_id
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_return_message
              admin_notes
              manual_process_reason
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              documents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            portal_claim_id
            portal_claim {
              id
              start_date
              end_date
              bbm_insured_entity_uuid
              company_id
              bbm_policy_number
              draft_claim_id
              family_name
              pet_name
              pet_type
              claim_amount
              condition
              date_of_loss
              continuation
              claim_status
              claim_status_type
              submitted_claim_id
              submitted_claim {
                id
                customer_id
                practice_id
                loss_uuid
                loss_ref
                case_uuid
                case_ref
                policy_number
                archive_claim_id
                portal_claim_id
                document_ids
                paired_claim
                PIO_loss_status
                PIO_loss_outcome_time
                PIO_loss_created_date
                PIO_loss_updated_date
                PIO_loss_total
                PIO_payment_amount
                assigned_to
                createdAt
                updatedAt
                item_type
                __typename
              }
              datetime_to_customer
              advice_note
              hub_claim_ref
              vet_claim_ref
              admin_return_message
              wru_exp_to_be_paid
              is_this_a_referral
              ref_practice_name
              claim_type
              documents {
                nextToken
                __typename
              }
              process_method
              admin_assigned_to_id
              admin_assigned_to {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              admin_submitted_by_id
              admin_submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              submitted_by_id
              submitted_by {
                id
                user_type
                video_vet_id
                video_vet_name
                company
                first_name
                last_name
                job_title
                role
                mobile
                verified_email
                password
                auth0_sub
                createdAt
                updatedAt
                __typename
              }
              returned_to_practice_count
              returned_to_practice_reason
              admin_notes
              manual_process_reason
              createdAt
              updatedAt
              __typename
            }
            document_ids
            paired_claim
            PIO_loss_status
            PIO_loss_outcome_time
            PIO_loss_created_date
            PIO_loss_updated_date
            PIO_loss_total
            PIO_payment_amount
            assigned_to
            createdAt
            updatedAt
            item_type
            __typename
          }
          datetime_to_customer
          advice_note
          hub_claim_ref
          vet_claim_ref
          admin_return_message
          wru_exp_to_be_paid
          is_this_a_referral
          ref_practice_name
          claim_type
          documents {
            items {
              id
              doc_type
              file_type
              URL
              PIO_URL
              uploaded_date
              user_ID
              customer_uuid
              company_id
              insured_entity_uuid
              draft_claim_id
              loss_uuid
              note
              status
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          process_method
          admin_assigned_to_id
          admin_assigned_to {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          admin_submitted_by_id
          admin_submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          submitted_by_id
          submitted_by {
            id
            user_type
            video_vet_id
            video_vet_name
            company
            first_name
            last_name
            job_title
            role
            mobile
            verified_email
            password
            auth0_sub
            createdAt
            updatedAt
            __typename
          }
          returned_to_practice_count
          returned_to_practice_reason
          admin_notes
          manual_process_reason
          createdAt
          updatedAt
          __typename
        }
        document_ids
        paired_claim
        PIO_loss_status
        PIO_loss_outcome_time
        PIO_loss_created_date
        PIO_loss_updated_date
        PIO_loss_total
        PIO_payment_amount
        assigned_to
        createdAt
        updatedAt
        item_type
        __typename
      }
      datetime_to_customer
      advice_note
      hub_claim_ref
      vet_claim_ref
      wru_exp_to_be_paid
      is_this_a_referral
      ref_practice_name
      claim_type
      process_method
      admin_assigned_to_id
      admin_assigned_to {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      returned_to_practice_count
      returned_to_practice_reason
      admin_return_message
      admin_notes
      manual_process_reason
      admin_submitted_by_id
      admin_submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      submitted_by_id
      submitted_by {
        id
        user_type
        video_vet_id
        video_vet_name
        company
        first_name
        last_name
        job_title
        role
        mobile
        verified_email
        password
        auth0_sub
        createdAt
        updatedAt
        __typename
      }
      documents {
        items {
          id
          doc_type
          file_type
          URL
          PIO_URL
          uploaded_date
          user_ID
          customer_uuid
          company_id
          insured_entity_uuid
          draft_claim_id
          loss_uuid
          note
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUtility = /* GraphQL */ `
  mutation CreateUtility(
    $input: CreateUtilityInput!
    $condition: ModelUtilityConditionInput
  ) {
    createUtility(input: $input, condition: $condition) {
      id
      claim_amount
      show_claim_invoices
      show_claim_clinical_history
      show_claim_treatment_notes
      show_claim_paid_information
      send_request_consent_email
      send_review_claim_email
      send_claim_submitted_email
      vet_practice_email_override
      customer_email_override
      bbm_handler_email
      covea_handler_email
      notification_override
      cc_email_override
      show_remittance_information
      expiry_duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUtility = /* GraphQL */ `
  mutation UpdateUtility(
    $input: UpdateUtilityInput!
    $condition: ModelUtilityConditionInput
  ) {
    updateUtility(input: $input, condition: $condition) {
      id
      claim_amount
      show_claim_invoices
      show_claim_clinical_history
      show_claim_treatment_notes
      show_claim_paid_information
      send_request_consent_email
      send_review_claim_email
      send_claim_submitted_email
      vet_practice_email_override
      customer_email_override
      bbm_handler_email
      covea_handler_email
      notification_override
      cc_email_override
      show_remittance_information
      expiry_duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUtility = /* GraphQL */ `
  mutation DeleteUtility(
    $input: DeleteUtilityInput!
    $condition: ModelUtilityConditionInput
  ) {
    deleteUtility(input: $input, condition: $condition) {
      id
      claim_amount
      show_claim_invoices
      show_claim_clinical_history
      show_claim_treatment_notes
      show_claim_paid_information
      send_request_consent_email
      send_review_claim_email
      send_claim_submitted_email
      vet_practice_email_override
      customer_email_override
      bbm_handler_email
      covea_handler_email
      notification_override
      cc_email_override
      show_remittance_information
      expiry_duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGenericEmailContent = /* GraphQL */ `
  mutation CreateGenericEmailContent(
    $input: CreateGenericEmailContentInput!
    $condition: ModelGenericEmailContentConditionInput
  ) {
    createGenericEmailContent(input: $input, condition: $condition) {
      id
      event_type
      vet_email_override
      vet_email_active
      vet_email_payload
      customer_email_override
      customer_email_active
      customer_email_payload
      admin_email_override
      admin_email_active
      admin_email_payload
      system_email_override
      system_email_payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGenericEmailContent = /* GraphQL */ `
  mutation UpdateGenericEmailContent(
    $input: UpdateGenericEmailContentInput!
    $condition: ModelGenericEmailContentConditionInput
  ) {
    updateGenericEmailContent(input: $input, condition: $condition) {
      id
      event_type
      vet_email_override
      vet_email_active
      vet_email_payload
      customer_email_override
      customer_email_active
      customer_email_payload
      admin_email_override
      admin_email_active
      admin_email_payload
      system_email_override
      system_email_payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGenericEmailContent = /* GraphQL */ `
  mutation DeleteGenericEmailContent(
    $input: DeleteGenericEmailContentInput!
    $condition: ModelGenericEmailContentConditionInput
  ) {
    deleteGenericEmailContent(input: $input, condition: $condition) {
      id
      event_type
      vet_email_override
      vet_email_active
      vet_email_payload
      customer_email_override
      customer_email_active
      customer_email_payload
      admin_email_override
      admin_email_active
      admin_email_payload
      system_email_override
      system_email_payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventLog = /* GraphQL */ `
  mutation CreateEventLog(
    $input: CreateEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    createEventLog(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      user_id
      date_time
      data
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventLog = /* GraphQL */ `
  mutation UpdateEventLog(
    $input: UpdateEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    updateEventLog(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      user_id
      date_time
      data
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventLog = /* GraphQL */ `
  mutation DeleteEventLog(
    $input: DeleteEventLogInput!
    $condition: ModelEventLogConditionInput
  ) {
    deleteEventLog(input: $input, condition: $condition) {
      id
      customer_id
      company_id
      user_id
      date_time
      data
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubmittedClaim = /* GraphQL */ `
  mutation CreateSubmittedClaim(
    $input: CreateSubmittedClaimInput!
    $condition: ModelSubmittedClaimConditionInput
  ) {
    createSubmittedClaim(input: $input, condition: $condition) {
      id
      customer_id
      practice_id
      loss_uuid
      loss_ref
      case_uuid
      case_ref
      policy_number
      archive_claim_id
      archive_claim {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      portal_claim_id
      portal_claim {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      document_ids
      paired_claim
      PIO_loss_status
      PIO_loss_outcome_time
      PIO_loss_created_date
      PIO_loss_updated_date
      PIO_loss_total
      PIO_payment_amount
      assigned_to
      createdAt
      updatedAt
      item_type
      __typename
    }
  }
`;
export const updateSubmittedClaim = /* GraphQL */ `
  mutation UpdateSubmittedClaim(
    $input: UpdateSubmittedClaimInput!
    $condition: ModelSubmittedClaimConditionInput
  ) {
    updateSubmittedClaim(input: $input, condition: $condition) {
      id
      customer_id
      practice_id
      loss_uuid
      loss_ref
      case_uuid
      case_ref
      policy_number
      archive_claim_id
      archive_claim {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      portal_claim_id
      portal_claim {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      document_ids
      paired_claim
      PIO_loss_status
      PIO_loss_outcome_time
      PIO_loss_created_date
      PIO_loss_updated_date
      PIO_loss_total
      PIO_payment_amount
      assigned_to
      createdAt
      updatedAt
      item_type
      __typename
    }
  }
`;
export const deleteSubmittedClaim = /* GraphQL */ `
  mutation DeleteSubmittedClaim(
    $input: DeleteSubmittedClaimInput!
    $condition: ModelSubmittedClaimConditionInput
  ) {
    deleteSubmittedClaim(input: $input, condition: $condition) {
      id
      customer_id
      practice_id
      loss_uuid
      loss_ref
      case_uuid
      case_ref
      policy_number
      archive_claim_id
      archive_claim {
        id
        start_date
        end_date
        draft_claim_id
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_return_message
        admin_notes
        manual_process_reason
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      portal_claim_id
      portal_claim {
        id
        start_date
        end_date
        bbm_insured_entity_uuid
        company_id
        bbm_policy_number
        draft_claim_id
        family_name
        pet_name
        pet_type
        claim_amount
        condition
        date_of_loss
        continuation
        claim_status
        claim_status_type
        submitted_claim_id
        submitted_claim {
          id
          customer_id
          practice_id
          loss_uuid
          loss_ref
          case_uuid
          case_ref
          policy_number
          archive_claim_id
          archive_claim {
            id
            start_date
            end_date
            draft_claim_id
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_return_message
            admin_notes
            manual_process_reason
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          portal_claim_id
          portal_claim {
            id
            start_date
            end_date
            bbm_insured_entity_uuid
            company_id
            bbm_policy_number
            draft_claim_id
            family_name
            pet_name
            pet_type
            claim_amount
            condition
            date_of_loss
            continuation
            claim_status
            claim_status_type
            submitted_claim_id
            submitted_claim {
              id
              customer_id
              practice_id
              loss_uuid
              loss_ref
              case_uuid
              case_ref
              policy_number
              archive_claim_id
              archive_claim {
                id
                start_date
                end_date
                draft_claim_id
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_return_message
                admin_notes
                manual_process_reason
                admin_submitted_by_id
                submitted_by_id
                createdAt
                updatedAt
                __typename
              }
              portal_claim_id
              portal_claim {
                id
                start_date
                end_date
                bbm_insured_entity_uuid
                company_id
                bbm_policy_number
                draft_claim_id
                family_name
                pet_name
                pet_type
                claim_amount
                condition
                date_of_loss
                continuation
                claim_status
                claim_status_type
                submitted_claim_id
                datetime_to_customer
                advice_note
                hub_claim_ref
                vet_claim_ref
                admin_return_message
                wru_exp_to_be_paid
                is_this_a_referral
                ref_practice_name
                claim_type
                process_method
                admin_assigned_to_id
                admin_submitted_by_id
                submitted_by_id
                returned_to_practice_count
                returned_to_practice_reason
                admin_notes
                manual_process_reason
                createdAt
                updatedAt
                __typename
              }
              document_ids
              paired_claim
              PIO_loss_status
              PIO_loss_outcome_time
              PIO_loss_created_date
              PIO_loss_updated_date
              PIO_loss_total
              PIO_payment_amount
              assigned_to
              createdAt
              updatedAt
              item_type
              __typename
            }
            datetime_to_customer
            advice_note
            hub_claim_ref
            vet_claim_ref
            admin_return_message
            wru_exp_to_be_paid
            is_this_a_referral
            ref_practice_name
            claim_type
            documents {
              items {
                id
                doc_type
                file_type
                URL
                PIO_URL
                uploaded_date
                user_ID
                customer_uuid
                company_id
                insured_entity_uuid
                draft_claim_id
                loss_uuid
                note
                status
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            process_method
            admin_assigned_to_id
            admin_assigned_to {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            admin_submitted_by_id
            admin_submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            submitted_by_id
            submitted_by {
              id
              user_type
              video_vet_id
              video_vet_name
              company
              first_name
              last_name
              job_title
              role
              mobile
              verified_email
              password
              auth0_sub
              createdAt
              updatedAt
              __typename
            }
            returned_to_practice_count
            returned_to_practice_reason
            admin_notes
            manual_process_reason
            createdAt
            updatedAt
            __typename
          }
          document_ids
          paired_claim
          PIO_loss_status
          PIO_loss_outcome_time
          PIO_loss_created_date
          PIO_loss_updated_date
          PIO_loss_total
          PIO_payment_amount
          assigned_to
          createdAt
          updatedAt
          item_type
          __typename
        }
        datetime_to_customer
        advice_note
        hub_claim_ref
        vet_claim_ref
        admin_return_message
        wru_exp_to_be_paid
        is_this_a_referral
        ref_practice_name
        claim_type
        documents {
          items {
            id
            doc_type
            file_type
            URL
            PIO_URL
            uploaded_date
            user_ID
            customer_uuid
            company_id
            insured_entity_uuid
            draft_claim_id
            loss_uuid
            note
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        process_method
        admin_assigned_to_id
        admin_assigned_to {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        admin_submitted_by_id
        admin_submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        submitted_by_id
        submitted_by {
          id
          user_type
          video_vet_id
          video_vet_name
          company
          first_name
          last_name
          job_title
          role
          mobile
          verified_email
          password
          auth0_sub
          createdAt
          updatedAt
          __typename
        }
        returned_to_practice_count
        returned_to_practice_reason
        admin_notes
        manual_process_reason
        createdAt
        updatedAt
        __typename
      }
      document_ids
      paired_claim
      PIO_loss_status
      PIO_loss_outcome_time
      PIO_loss_created_date
      PIO_loss_updated_date
      PIO_loss_total
      PIO_payment_amount
      assigned_to
      createdAt
      updatedAt
      item_type
      __typename
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      title
      description
      tag
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      title
      description
      tag
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      title
      description
      tag
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStatsSourceStatus = /* GraphQL */ `
  mutation CreateStatsSourceStatus(
    $input: CreateStatsSourceStatusInput!
    $condition: ModelStatsSourceStatusConditionInput
  ) {
    createStatsSourceStatus(input: $input, condition: $condition) {
      id
      company_id
      company_name
      draft_new
      draft_incomplete
      draft_complete
      draft_archived
      with_client
      client_approved
      auto_approved
      returned_by_client
      returned_by_admin
      archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStatsSourceStatus = /* GraphQL */ `
  mutation UpdateStatsSourceStatus(
    $input: UpdateStatsSourceStatusInput!
    $condition: ModelStatsSourceStatusConditionInput
  ) {
    updateStatsSourceStatus(input: $input, condition: $condition) {
      id
      company_id
      company_name
      draft_new
      draft_incomplete
      draft_complete
      draft_archived
      with_client
      client_approved
      auto_approved
      returned_by_client
      returned_by_admin
      archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStatsSourceStatus = /* GraphQL */ `
  mutation DeleteStatsSourceStatus(
    $input: DeleteStatsSourceStatusInput!
    $condition: ModelStatsSourceStatusConditionInput
  ) {
    deleteStatsSourceStatus(input: $input, condition: $condition) {
      id
      company_id
      company_name
      draft_new
      draft_incomplete
      draft_complete
      draft_archived
      with_client
      client_approved
      auto_approved
      returned_by_client
      returned_by_admin
      archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStatsClaimsThroughHub = /* GraphQL */ `
  mutation CreateStatsClaimsThroughHub(
    $input: CreateStatsClaimsThroughHubInput!
    $condition: ModelStatsClaimsThroughHubConditionInput
  ) {
    createStatsClaimsThroughHub(input: $input, condition: $condition) {
      id
      company_id
      company_name
      added
      moved_to_pio
      currently_in_hub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStatsClaimsThroughHub = /* GraphQL */ `
  mutation UpdateStatsClaimsThroughHub(
    $input: UpdateStatsClaimsThroughHubInput!
    $condition: ModelStatsClaimsThroughHubConditionInput
  ) {
    updateStatsClaimsThroughHub(input: $input, condition: $condition) {
      id
      company_id
      company_name
      added
      moved_to_pio
      currently_in_hub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStatsClaimsThroughHub = /* GraphQL */ `
  mutation DeleteStatsClaimsThroughHub(
    $input: DeleteStatsClaimsThroughHubInput!
    $condition: ModelStatsClaimsThroughHubConditionInput
  ) {
    deleteStatsClaimsThroughHub(input: $input, condition: $condition) {
      id
      company_id
      company_name
      added
      moved_to_pio
      currently_in_hub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStatsCycleTime = /* GraphQL */ `
  mutation CreateStatsCycleTime(
    $input: CreateStatsCycleTimeInput!
    $condition: ModelStatsCycleTimeConditionInput
  ) {
    createStatsCycleTime(input: $input, condition: $condition) {
      id
      company_id
      company_name
      date_claim_created
      date_claim_closed
      duration_draft
      duration_with_client
      duration_returned_by_admin
      duration_returned_by_client
      duration_approved
      duration_archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStatsCycleTime = /* GraphQL */ `
  mutation UpdateStatsCycleTime(
    $input: UpdateStatsCycleTimeInput!
    $condition: ModelStatsCycleTimeConditionInput
  ) {
    updateStatsCycleTime(input: $input, condition: $condition) {
      id
      company_id
      company_name
      date_claim_created
      date_claim_closed
      duration_draft
      duration_with_client
      duration_returned_by_admin
      duration_returned_by_client
      duration_approved
      duration_archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStatsCycleTime = /* GraphQL */ `
  mutation DeleteStatsCycleTime(
    $input: DeleteStatsCycleTimeInput!
    $condition: ModelStatsCycleTimeConditionInput
  ) {
    deleteStatsCycleTime(input: $input, condition: $condition) {
      id
      company_id
      company_name
      date_claim_created
      date_claim_closed
      duration_draft
      duration_with_client
      duration_returned_by_admin
      duration_returned_by_client
      duration_approved
      duration_archived
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAugmentedDocuments = /* GraphQL */ `
  mutation CreateAugmentedDocuments(
    $input: CreateAugmentedDocumentsInput!
    $condition: ModelAugmentedDocumentsConditionInput
  ) {
    createAugmentedDocuments(input: $input, condition: $condition) {
      id
      augmented_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAugmentedDocuments = /* GraphQL */ `
  mutation UpdateAugmentedDocuments(
    $input: UpdateAugmentedDocumentsInput!
    $condition: ModelAugmentedDocumentsConditionInput
  ) {
    updateAugmentedDocuments(input: $input, condition: $condition) {
      id
      augmented_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAugmentedDocuments = /* GraphQL */ `
  mutation DeleteAugmentedDocuments(
    $input: DeleteAugmentedDocumentsInput!
    $condition: ModelAugmentedDocumentsConditionInput
  ) {
    deleteAugmentedDocuments(input: $input, condition: $condition) {
      id
      augmented_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRawDocuments = /* GraphQL */ `
  mutation CreateRawDocuments(
    $input: CreateRawDocumentsInput!
    $condition: ModelRawDocumentsConditionInput
  ) {
    createRawDocuments(input: $input, condition: $condition) {
      id
      raw_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRawDocuments = /* GraphQL */ `
  mutation UpdateRawDocuments(
    $input: UpdateRawDocumentsInput!
    $condition: ModelRawDocumentsConditionInput
  ) {
    updateRawDocuments(input: $input, condition: $condition) {
      id
      raw_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRawDocuments = /* GraphQL */ `
  mutation DeleteRawDocuments(
    $input: DeleteRawDocumentsInput!
    $condition: ModelRawDocumentsConditionInput
  ) {
    deleteRawDocuments(input: $input, condition: $condition) {
      id
      raw_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLogsDocuments = /* GraphQL */ `
  mutation CreateLogsDocuments(
    $input: CreateLogsDocumentsInput!
    $condition: ModelLogsDocumentsConditionInput
  ) {
    createLogsDocuments(input: $input, condition: $condition) {
      id
      logs_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLogsDocuments = /* GraphQL */ `
  mutation UpdateLogsDocuments(
    $input: UpdateLogsDocumentsInput!
    $condition: ModelLogsDocumentsConditionInput
  ) {
    updateLogsDocuments(input: $input, condition: $condition) {
      id
      logs_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLogsDocuments = /* GraphQL */ `
  mutation DeleteLogsDocuments(
    $input: DeleteLogsDocumentsInput!
    $condition: ModelLogsDocumentsConditionInput
  ) {
    deleteLogsDocuments(input: $input, condition: $condition) {
      id
      logs_JSON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOCRLookup = /* GraphQL */ `
  mutation CreateOCRLookup(
    $input: CreateOCRLookupInput!
    $condition: ModelOCRLookupConditionInput
  ) {
    createOCRLookup(input: $input, condition: $condition) {
      id
      doc_type
      doc_uuid
      customer_uuid
      case_uuid
      loss_uuid
      insured_entity_uuid
      policy_uuid
      raw_json_id
      aug_json_id
      log_json_id
      raw_json_url
      aug_json_url
      log_json_url
      file_uploaded_date
      file_name
      url
      csv_created_at
      ocr_stored_at
      ocr_augmented_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOCRLookup = /* GraphQL */ `
  mutation UpdateOCRLookup(
    $input: UpdateOCRLookupInput!
    $condition: ModelOCRLookupConditionInput
  ) {
    updateOCRLookup(input: $input, condition: $condition) {
      id
      doc_type
      doc_uuid
      customer_uuid
      case_uuid
      loss_uuid
      insured_entity_uuid
      policy_uuid
      raw_json_id
      aug_json_id
      log_json_id
      raw_json_url
      aug_json_url
      log_json_url
      file_uploaded_date
      file_name
      url
      csv_created_at
      ocr_stored_at
      ocr_augmented_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOCRLookup = /* GraphQL */ `
  mutation DeleteOCRLookup(
    $input: DeleteOCRLookupInput!
    $condition: ModelOCRLookupConditionInput
  ) {
    deleteOCRLookup(input: $input, condition: $condition) {
      id
      doc_type
      doc_uuid
      customer_uuid
      case_uuid
      loss_uuid
      insured_entity_uuid
      policy_uuid
      raw_json_id
      aug_json_id
      log_json_id
      raw_json_url
      aug_json_url
      log_json_url
      file_uploaded_date
      file_name
      url
      csv_created_at
      ocr_stored_at
      ocr_augmented_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
